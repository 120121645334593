<template>
  <v-card flat>
    <slot name="cardTitle"></slot>
    <v-card-text>
      <v-form ref="form">
        <v-autocomplete
            v-model="regInfo.regionId"
            :items="regions"
            item-value="code"
            item-text="name"
            :rules="[v => !!v]"
            label="Субъект (регион)"
            no-data-text="Нет данных"
        ></v-autocomplete>
        <v-autocomplete
            v-if="!isProfileCard"
            v-model="regInfo.organizationId"
            :items="organizations"
            item-value="code"
            item-text="name"
            :rules="[v => !!v]"
            label="Наименование организации, на базе которой создан Методический аккредитационно-симуляционный центр (МАСЦ)"
            no-data-text="Организаций не найдено"
        ></v-autocomplete>
        <v-text-field
            v-if="isProfileCard"
            v-model="regInfo.orgName"
            label="Наименование организации, на базе которой создан Методический аккредитационно-симуляционный центр (МАСЦ)"
            readonly />
        <v-text-field
            v-if="!isProfileCard"
            label="Фамилия, имя, отчество (полностью)  руководителя МАСЦ"
            v-model="regInfo.mascBossFullname" :rules="[v => !!v]" />

        <v-text-field v-model="regInfo.mascBossDegree" label="Ученая степень, звание (при наличии)" />

        <v-text-field
            v-if="!isProfileCard"
            label="Адрес электронной почты (актуальный)"
            v-model="regInfo.mascBossEmail" type="email" :rules="[emailRule]" />
        <v-text-field
            v-if="!isProfileCard"
            label="Контактный телефон"
            v-model="regInfo.mascBossPhone" :rules="[v => !!v]" />
        <v-btn
            outlined
            color="primary"
            @click="showRespPersonDialog=!showRespPersonDialog">
          Добавить ОЛ
        </v-btn>
        <v-dialog v-model="showRespPersonDialog" max-width="70%">
          <v-card class="pa-5">
            <v-card-title>
              {{ respPersonDialogMode === 'edit' ? 'Редактирование' : 'Добавление' }} ответственного лица
            </v-card-title>
            <v-card-text>
              <v-form ref="respPersonForm">
                <v-autocomplete
                    v-model="tmpRespPerson.speciality"
                    :items="filteredSpecialities"
                    item-value="code"
                    item-text="name"
                    :rules="[v => !!v]"
                    label="Специальность ответственного лица за разработку ОС"
                    multiple
                    return-object
                    no-data-text="Нет данных"
                />
                <v-text-field
                    v-model="tmpRespPerson.fullname"
                    label="Фамилия, имя, отчество (полностью) ответственного лица за разработку ОС
по клиническим рекомендациям Минздрава России для ПСА 2025 года"
                    :rules="[v => !!v]" />
                <v-text-field v-model="tmpRespPerson.position"
                              label="Занимаемая должность"
                              :rules="[v => !!v]" />

                <v-text-field v-model="tmpRespPerson.degree" label="Ученая степень, звание (при наличии)" />
                <v-text-field
                    label="Адрес электронной почты (актуальный)"
                    v-model="tmpRespPerson.email" type="email" :rules="[emailRule]" />

                <v-text-field
                    label="Контактный телефон"
                    v-model="tmpRespPerson.phone" :rules="[v => !!v]" />
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn
                  outlined
                  color="primary"
                  @click="closeRespPersonDialog">
                Отмена
              </v-btn>
              <v-btn
                  outlined
                  color="primary"
                  @click="addEditRespPerson">
                {{ respPersonDialogMode === 'edit' ? 'Применить' : 'Добавить' }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
      <span v-if="regInfo.respPersons.length > 0">
        <br />
        <h3>
          Лица ответственные за разработку ОС по клиническим рекомендациям Минздрава России для ПСА 2025 года
        </h3>
        <br />
        <v-expansion-panels accordion style="max-width: 40%">
          <v-expansion-panel
              v-for="(person, index) in regInfo.respPersons"
              :key="index"
          >
            <v-expansion-panel-header>
              <v-row style="display: flex; align-items: center; line-height: 16px">
                <v-col>
                  <b>ФИО:</b> {{ person.fullname }}
                  <br />
                  <b>Специальность: </b>{{ getElipsedSpeciality(person.speciality, 30) }}
                </v-col>
                <v-col cols="3" class="mr-1">
                  <v-tooltip right>
                    <template #activator="{ on, attrs }">
                      <v-icon
                          class="pl-1 pr-1"
                          @click.stop="editAddedRespPerson(person, index)"
                          v-bind="attrs"
                          v-on="on">
                        mdi-pencil
                      </v-icon>
                    </template>
                    <span>Редактировать</span>
                  </v-tooltip>
                  <v-tooltip right>
                    <template #activator="{ on, attrs }">
                      <v-icon
                          @click.stop="removeAddedRespPerson(person, index)"
                          v-bind="attrs"
                          v-on="on">
                        mdi-delete
                      </v-icon>
                    </template>
                    <span>Удалить</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-select
                  v-model="person.speciality"
                  :items="specialities"
                  item-value="code"
                  item-text="name"
                  disabled
                  multiple
                  return-object
                  label="Специальность"
              />
              <v-text-field
                  v-model="person.position"
                  label="Занимаемая должность"
                  disabled
              />

              <v-text-field
                  v-model="person.degree"
                  label="Ученая степень, звание (при наличии)"
                  disabled
              />
              <v-text-field
                  v-model="person.email"
                  label="Адрес электронной почты (актуальный)"
                  disabled
                  type="email" />

              <v-text-field
                  v-model="person.phone"
                  label="Контактный телефон"
                  disabled />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </span>
    </v-card-text>
    <v-card-actions v-if="showCardActions">
      <v-spacer />
      <v-btn
          outlined
          color="primary"
          @click="close"
      >
        Отмена
      </v-btn>
      <v-btn
          outlined
          color="primary"
          @click="doSave"
          :disabled="disabledSave"
      >
        Сохранить
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { dateMixin, dbMixin, formsMixin, registerMixin } from "@/mixins"

// Я вынес в функцию, чтобы при необходимости можно было сбросить данные.
// в данном случае сброс данных идет за счет перемаунчивания компонента в RespMascList
// (т.к к компоненту привязан v-if='dialog', соответственно при закрытии диалога выполняется destroy)
// но вынос инициализации в отдельный метод решил оставить. и метод resetData тоже.
const initializeData = () => {
  return {
    menu: false,
    disabledSave: false,
    role: 'respMasc',
    showRespPersonDialog: false,
    respPersonDialogMode: 'add',
    chosenSpeciality: '',
    regions: [],
    resp_masc_org: [],
    specialities_with_level: [],
    prevTmpPersonIndex: '',
    prevTmpPerson: { speciality: "", fullname: "", position: "", degree: "", email: "", phone: "" },
    tmpRespPerson: { speciality: "", fullname: "", position: "", degree: "", email: "", phone: "" },
    regInfo: {
      mode: 'add',
      organizationId: '',
      orgName: '',
      regionId: '',
      mascBossId: '',
      mascBossFullname: '',
      mascBossEmail: '',
      mascBossPhone: '',
      mascBossDegree: '',
      respPersons: [],
      newMascBoss: false,
      mascBossEmailChanged: false,
    }
  }
}
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "respMasc",
  mixins: [dbMixin, dateMixin, registerMixin, formsMixin],
  data () {
    return initializeData()
  },
  async beforeMount () {
    await this.getCatalogData('specialities_with_level')
    await this.getCatalogData('regions')
    await this.getCatalogData('resp_masc_org')
  },
  watch: {
    'regInfo.mascBossEmail' () {
      if (this.regInfo.mode == 'edit') {
        this.regInfo.mascBossEmailChanged = true
      }

    },
    // 'regInfo.respEmail' () {
    //   if (this.regInfo.mode == 'edit') {
    //     this.regInfo.respEmailChanged = true
    //   }
    // }
  },
  computed: {
    specialities () {
      return this.specialities_with_level
    },
    organizations () {
      if (this.regInfo.mode === 'edit') {
        return this.resp_masc_org.filter((el) => {
          return el.code == this.regInfo.organizationId
        })
      } else {
        return this.resp_masc_org.filter((el) => {
          return !el.is_used
        })
      }
    },
    filteredSpecialities () {
      // TODO: поправить
      return this.specialities.filter(el => el.code != '')
    },
    isProfileCard () {
      if (this.loadedInfo === null || this.loadedInfo === undefined) {
        return false
      }
      return Object.keys(this.loadedInfo).length > 0
    },
  },
  methods: {
    getElipsedSpeciality (entity, length) {
      if (Array.isArray(entity)) {
        entity = entity.map(el => el.name).join(';')
      }
      if (entity.length < length) {
        return entity
      }
      return entity.slice(0, length) + '...'
    },
    async removeAddedRespPerson (person, index) {
      //  Удаление информации о добавленном ответственном лице за разработку ОС
      // Кнопка удаления расположена в expansion-panel в заголовке
      const res = await this.$dialog.confirm({ text: `Открепить ${person.fullname}?` })
      if (res) {
        this.regInfo.respPersons.splice(index, 1)
      }
    },
    editAddedRespPerson (person, index) {
      //  Редактирование информации о добавленном ответственном лице за разработку ОС
      // Кнопка редактирования расположена в expansion-panel в заголовке

      // Сохранение значения на случай нажатия кнопки Отмена
      this.prevTmpPerson = { ...person }
      this.prevTmpPersonIndex = index
      this.tmpRespPerson = person
      this.showRespPersonDialog = true
      this.respPersonDialogMode = 'edit'
    },
    clearTmpPerson () {
      // Зануление временного объекта который используется в диалоговом окне добавления ОЛ
      this.tmpRespPerson = {}
    },
    closeRespPersonDialog () {
      this.showRespPersonDialog = false
      this.regInfo.respPersons[this.prevTmpPersonIndex] = { ...this.prevTmpPerson }
      this.prevTmpPersonIndex = ''
      this.prevTmpPerson = {}
      this.clearTmpPerson()
      this.respPersonDialogMode = 'add'
    },
    // Добавление/редактирование ОЛ
    async addEditRespPerson () {
      if (!this.$refs.respPersonForm.validate()) {
        return
      }

      if ((this.regInfo.mode === 'edit' || this.isProfileCard) && this.respPersonDialogMode === 'edit') {
        if (this.tmpRespPerson.email !== this.prevTmpPerson.email) {
          this.tmpRespPerson.respEmailChanged = true
          const title = `Вы изменили email ответственного лица за разработку ОС. Это новый пользователь?`
          const res = await this.emailChangedPrompt(title, 'newResp')
          if (res === undefined) {
            return
          }
        }
      }
      if (this.respPersonDialogMode !== 'edit') {
        this.tmpRespPerson.newResp = true
        this.regInfo.respPersons.push({ ...this.tmpRespPerson })
      }

      this.clearTmpPerson()
      this.showRespPersonDialog = false
      this.respPersonDialogMode = 'add'
    },
    async doSave () {
      if (this.regInfo.mascBossEmailChanged) {
        const title = `Вы изменили email руководителя МАСЦ. Это новый пользователь?`
        const res = await this.emailChangedPrompt(title, 'newMascBoss')

        if (res === undefined) {
          return
        }
      }

      if (!(this.regInfo.respPersons.length > 0)) {
        await this.$dialog.error({ text: 'Укажите ответственных лиц', title: 'Отсутствие ответственных лиц'})
        return
      }

      // if (this.regInfo.respEmailChanged) {
      //   const title = `Вы изменили email ответственного лица за разработку ОС. Это новый пользователь?`
      //   const res = await this.emailChangedPrompt(title, 'newResp')
      //   if (res === undefined) {
      //     return
      //   }
      // }

      this.disabledSave = true
      await this.save()
      this.disabledSave = false
    },
    async emailChangedPrompt (title, field) {
      // this.regInfo[field] = await this.$dialog.confirm({
      //   text: title
      // })
      // return this.regInfo[field]
      this.tmpRespPerson[field] = await this.$dialog.confirm({ text: title })
      return this.tmpRespPerson[field]
    },
    resetData () {
      const data = initializeData()
      Object.keys(data).forEach(k => this[k] = data[k])
    },
    emailRule (value) {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!value || !regex.test(value)) {
        return 'Некорретно введена электронная почта';
      } else {
        return true;
      }
    }
  }
}
</script>

<style scoped>
/* Класс для того чтобы приподнять v-text-field чуть выше, чтобы все было в линию*/
.numberTextField {
  position: relative;
  bottom: 8px;
  margin-left: 15px;
  width: 90px;
}

.inlineBlocks {
  display: flex;
}
</style>