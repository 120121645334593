import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({ user: state.user })
})

export default new Vuex.Store({
  state: {
    user: {
      fullname: '',
      username: '',
      userId: '',
      roles: []
    },

    rolesMap: {
      admin: 'admin',
      package_expert: 'packageExpert',
      case_expert: 'caseExpert',
      bank_expert: 'bankExpert',
      accred: 'accred',
      webinar_participant: 'webinarParticipant',
      worksheet_participant: 'worksheetParticipant',
      stdpatient: 'stdpatient',
      resp_org: 'respOrg',
      accred_info_extended: 'accredInfoExtended',
      resp_masc: 'respMasc'
    },
    tables: {}
  },
  plugins: [vuexLocal.plugin],
  getters: {
    USER: state => state.user,
    USER_ROLES: state => state.user.roles,
    GET_SAVED_TABLE: state => (tableName) => {
      return state.tables[tableName]
    },
  },
  mutations: {
    SET_USER (state, user) {
      state.user = Object.assign({}, user);
    },
    UNSET_USER (state) {
      state.user = {
        fullname: '',
        username: '',
      };
    },
    SAVE_TABLE (state, { tableName, data }) {
      if (!state.tables[tableName]) {
        state.tables[tableName] = {}
      }
      state.tables[tableName].filter = data
    },
  },
  actions: {
    fetchUser (store, user) {
      // Позже метод будет изменен на нормальную авторизацию
      store.commit("SET_USER", user);
    },
    unFetchUser (store) {
      store.commit('UNSET_USER')
    }
  },
  modules: {}
})
