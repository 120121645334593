<template>
<!--  <v-form v-if="!$route.query.id">-->
<!--    Адрес страницы не действителен-->
<!--  </v-form>-->
  <v-form ref="studyResWorksheet">
    <v-card
        max-width="640"
        class="mx-auto"
        flat
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-app-bar
                  dark
                  color="#4285F4"
                  height="10px"
              >
              </v-app-bar>
              <v-card-title class="text-h5">
                Анкета по итогам обучения
              </v-card-title>
              <div class="subtitle">
                <p>Уважаемые коллеги!</p>

                <p>В целях оценки эффективности работы по реализации дополнительных профессиональных образовательных
                  программ просим Вас определить степень удовлетворённости результатами обучения по программе
                  «Измерения в управлении качеством образования» для лиц из числа профессорско-преподавательских
                  и научно-исследовательских кадров системы медицинского образования,
                  ответив на ряд предложенных вопросов.</p>

                <p>В анкете будет использована шкала: 5 – отлично, 4 – хорошо, 3 – удовлетворительно, 2 - плохо</p>

                <p>Гарантируем, что в ходе исследования будет соблюдена полная анонимность,
                  результаты анкетирования будут использованы только в обобщенном виде.</p>

                <p>Заранее благодарим за ответы!</p>
              </div>
              <v-card-text>
                <p class="errorColor">* - Обязательный вопрос</p>
              </v-card-text>
            </v-card>
          </v-col>

<!--          <v-col cols="12" v-if="!USER.userId">-->
<!--            <v-card>-->
<!--              <div style="display: flex; align-items: flex-start">-->
<!--                <span class="subtitle subtitle&#45;&#45;width95">-->
<!--                  Укажите адрес электронной почты-->
<!--                </span>-->
<!--                <span class="subtitle subtitle&#45;&#45;padded errorColor">*</span>-->
<!--              </div>-->
<!--              <v-card-text>-->
<!--                <v-text-field-->
<!--                    v-model="info.email"-->
<!--                    :rules="[v => !!v]"-->
<!--                    label="Электронная почта"></v-text-field>-->
<!--              </v-card-text>-->
<!--            </v-card>-->
<!--          </v-col>-->

          <v-col cols="12">
            <v-card>
              <div style="display: flex; align-items: flex-start">
                <span class="subtitle subtitle--width95">
                  Насколько Вы <b>осуществили</b> достижение <b>личной цели</b> обучения?
                </span>
                <span class="subtitle subtitle--padded errorColor">*</span>
              </div>
              <v-card-text>
                <v-radio-group
                    v-model="info.goalReach"
                    row
                    label="Критерии оценки"
                    :rules="[v => v !== '']">
                  <v-radio
                      v-for="n in [5, 4, 3, 2]"
                      :key="`goalReach-${n}`"
                      :label="n"
                      :value="n"
                  />
                </v-radio-group>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <div style="display: flex; align-items: flex-start">
                <span class="subtitle subtitle--width95">
                  Определите уровень <b>удовлетворенности организацией учебного процесса</b> в рамках
                  обучения
                </span>
                <span class="subtitle subtitle--padded errorColor">*</span>
              </div>
              <v-card-text>
                <v-radio-group
                    v-model="info.processSatisfaction"
                    row
                    label="Критерии оценки"
                    :rules="[v => v !== '']">
                  <v-radio
                      v-for="n in [5, 4, 3, 2]"
                      :key="`goalReach-${n}`"
                      :label="n"
                      :value="n"
                  />
                </v-radio-group>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <div style="display: flex; align-items: flex-start">
                <span class="subtitle subtitle--width95">
                  Оцените содержание учебных материалов и методического обеспечения,
                  предложенных на курсе
                </span>
                <span class="subtitle subtitle--padded errorColor">*</span>
              </div>
              <v-card-text>
                <v-row class="centerAligment">
                  <v-col cols="6">
                    Новизна информации
                  </v-col>
                  <v-col cols="6">
                    <v-radio-group
                        v-model="info.infoNovelty"
                        row
                        :rules="[v => v !== '']"
                        class="spacedEvenly"
                    >
                      <v-radio
                          v-for="n in [5, 4, 3, 2]"
                          :key="`infoNovelty-${n}`"
                          :label="n"
                          :value="n"
                      >
                        <template #label>
                          <div style="position:absolute; bottom: 20px; right:17px">{{ n }}</div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row
                    class="centerAligment"
                    v-for="(value, key) in studyMaterialQuestions"
                    :key="`row-${key}`">
                  <v-col cols="6">
                    {{ value }}
                  </v-col>
                  <v-col cols="6">
                    <v-radio-group
                        v-model="info[key]"
                        row
                        :rules="[v => v !== '']"
                        class="spacedEvenly">
                      <v-radio
                          v-for="n in ['5', '4', '3', '2']"
                          :key="`${key}-${n}`"
                          :value="n"

                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <div style="display: flex; align-items: flex-start">
                <span class="subtitle subtitle--width95">
                  Оцените степень влияния данного обучения <b>на эффективность</b> Вашей профессиональной
                  деятельности
                </span>
                <span class="subtitle subtitle--padded errorColor">*</span>
              </div>
              <v-card-text>
                <v-radio-group
                    v-model="info.effectiveness"
                    row
                    label="Критерии оценки"
                    :rules="[v => v !== '']">
                  <v-radio
                      v-for="n in [5, 4, 3, 2]"
                      :key="`effectiveness-${n}`"
                      :label="n"
                      :value="n"
                  />
                </v-radio-group>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <div style="display: flex; align-items: flex-start">
                <span class="subtitle subtitle--width95">
                  Оцените степень совершенствования и (или) получения Вами новой компетенции, формируемой
                  (развиваемой) в рамках обучения на курсе:
                  <b>готовность к разработке и обновлению фондов оценочных средств по преподаваемой дисциплине</b>
                </span>
                <span class="subtitle subtitle--padded errorColor">*</span>
              </div>
              <v-card-text>
                <v-radio-group
                    v-model="info.competence"
                    row
                    label="Критерии оценки"
                    :rules="[v => v !== '']">
                  <v-radio
                      v-for="n in [5, 4, 3, 2]"
                      :key="`competence-${n}`"
                      :label="n"
                      :value="n"
                  />
                </v-radio-group>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <div style="display: flex; align-items: flex-start">
                <span class="subtitle subtitle--width95">
                  Используете ли Вы в своей работе <b>знания, полученные</b> в ходе курса обучения?
                </span>
                <span class="subtitle subtitle--padded errorColor">*</span>
              </div>
              <v-card-text>
                <v-radio-group
                    v-model="info.knowledgeUse"
                    row
                    label="Критерии оценки"
                    :rules="[v => v !== '']">
                  <v-radio
                      v-for="n in [5, 4, 3, 2]"
                      :key="`knowledgeUse-${n}`"
                      :label="n"
                      :value="n"
                  />
                </v-radio-group>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <div style="display: flex; align-items: flex-start">
                <span class="subtitle subtitle--width95">
                  Насколько обучение повлияло на <b>желание</b> продолжать <b>узнавать новое</b>?
                </span>
                <span class="subtitle subtitle--padded errorColor">*</span>
              </div>
              <v-card-text>
                <v-radio-group
                    v-model="info.enhanceWish"
                    row
                    label="Критерии оценки"
                    :rules="[v => v !== '']">
                  <v-radio
                      v-for="n in [5, 4, 3, 2]"
                      :key="`enhanceWish-${n}`"
                      :label="n"
                      :value="n"
                  />
                </v-radio-group>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <div style="display: flex; align-items: flex-start">
                <span class="subtitle subtitle--width95">
                  Какие из предложенных тем <b>Вам</b> были бы <b>интересны</b> в дальнейшем?
                </span>
                <span class="subtitle subtitle--padded errorColor">*</span>
              </div>
              <v-card-text>
                <v-checkbox
                    v-for="(value, key) in futureInterestOptions"
                    :key="`futInterest-${key}`"
                    v-model="info.futureInterest"
                    :label="value"
                    :value="key"
                    dense
                    :rules="[v => v.length > 0]"
                />
                <v-text-field
                    v-if="info.futureInterest.includes('other')"
                    v-model="info.futureInterestText"
                    placeholder="Другое" />
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <div style="display: flex; align-items: flex-start">
                <span class="subtitle subtitle--width95">
                  Оцените Ваше желание поделиться полученной на курсе информацией с коллегами
                </span>
                <span class="subtitle subtitle--padded errorColor">*</span>
              </div>
              <v-card-text>
                <v-radio-group
                    v-model="info.shareDesire"
                    row
                    label="Критерии оценки"
                    :rules="[v => v !== '']">
                  <v-radio
                      v-for="n in [5, 4, 3, 2]"
                      :key="`shareDesire-${n}`"
                      :label="n"
                      :value="n"
                  />
                </v-radio-group>
              </v-card-text>
            </v-card>
          </v-col>


          <v-col
              cols="12"
              v-for="(value, key) in estimatePersonKeys"
              :key="`estimate-${key}`">
            <v-card>
              <div style="display: flex; align-items: flex-start">
                <span class="subtitle subtitle--width95">
                  Оцените работу преподавателя на курсе: <b>{{ value }}</b>
                </span>
                <span class="subtitle subtitle--padded errorColor">*</span>
              </div>
              <v-card-text>
                <v-row class="centerAligment">
                  <v-col cols="6">
                    Общая оценка преподавателя
                  </v-col>
                  <v-col cols="6">
                    <v-radio-group
                        v-model="info[`${key}GeneralGrade`]"
                        row
                        :rules="[v => v !== '']"
                        class="spacedEvenly"
                    >
                      <v-radio
                          v-for="n in [5, 4, 3, 2]"
                          :key="`${key}GeneralGrade-${n}`"
                          :label="n"
                          :value="n"
                      >
                        <template #label>
                          <div style="position:absolute; bottom: 20px; right:17px">{{ n }}</div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row
                    class="centerAligment"
                    v-for="(value2, key2) in workEstimate"
                    :key="`row-${key2}`">
                  <v-col cols="6">
                    {{ value2 }}
                  </v-col>
                  <v-col cols="6">
                    <v-radio-group
                        v-model="info[`${key}${key2}`]"
                        row
                        :rules="[v => v !== '']"
                        class="spacedEvenly">
                      <v-radio
                          v-for="n in ['5', '4', '3', '2']"
                          :key="`${key2}-${n}`"
                          :value="n"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <div style="display: flex; align-items: flex-start">
                <span class="subtitle subtitle--width95">
                  Ваши пожелания
                </span>
                <span class="subtitle subtitle--padded errorColor">*</span>
              </div>
              <v-card-text>
                <v-text-field v-model="info.suggestion" label="Мой ответ"></v-text-field>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <div style="display: flex; align-items: flex-start">
                <span class="subtitle subtitle--width95">
                  <p>Просим Вас сообщить некоторые сведения о себе:</p>
                  Ваш стаж работы <b>в должности преподавателя</b>
                </span>
                <span class="subtitle subtitle--padded errorColor">*</span>
              </div>
              <v-card-text>
                <v-radio-group
                    v-model="info.teacherExperience"
                    :rules="[v => v !== '']">
                  <v-radio
                      label="нет стажа"
                      value="noExperience"
                  ></v-radio>
                  <v-radio
                      label="до 5 лет"
                      value="under5"
                  />
                  <v-radio
                      label="от 5 до 9 лет"
                      value="5to9"
                  />
                  <v-radio
                      label="от 10 до 14 лет"
                      value="10to14"
                  />
                  <v-radio
                      label="от 15 до 20 лет"
                      value="15to20"
                  />
                  <v-radio
                      label="более 20 лет"
                      value="over20"
                  />

                </v-radio-group>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card>
              <div style="display: flex; align-items: flex-start">
                <span class="subtitle subtitle--width95">
                  Участвовали ли Вы ранее в подобной программе обучения?
                </span>
                <span class="subtitle subtitle--padded errorColor">*</span>
              </div>
              <v-card-text>
                <v-radio-group
                    v-model="info.earlyExperience"
                    :rules="[v => v !== '']">
                  <v-radio
                      label="да"
                      value="yes"
                  ></v-radio>
                  <v-radio
                      label="нет"
                      value="no"
                  />
                </v-radio-group>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <div style="display: flex; align-items: flex-start;">
                <div class="subtitle subtitle--width95">
                  Из каких источников Вы узнали о программе?
                </div>
                <div class="subtitle subtitle--padded errorColor">*</div>
              </div>
              <v-card-text>
                <v-radio-group
                    v-model="info.sources"
                    :rules="[v => v !== '']">
                  <v-radio
                      label="на портале НМО"
                      value="portal"
                  ></v-radio>
                  <v-radio
                      label="от коллег"
                      value="colleagues"
                  />
                  <v-radio
                      label="от Методического центра"
                      value="medCenter"
                  ></v-radio>
                  <v-radio
                      label="другое"
                      value="other"
                  />
                </v-radio-group>
                <v-text-field v-if="info.sources === 'other'" v-model="info.sourcesText" placeholder="Другое" />
              </v-card-text>
            </v-card>
          </v-col>

        </v-row>
      </v-container>

      <v-btn color="primary" class="worksheetSubmitBtn" @click="submitForm">Отправить</v-btn>
    </v-card>
  </v-form>
</template>

<script>
import { dbMixin, formsMixin } from "@/mixins"
import { mapGetters } from "vuex"

export default {
  name: "AnReanimWorksheet",
  mixins: [dbMixin, formsMixin],
  data () {
    return {
      studyMaterialQuestions:
          {
            infoActuality: 'Актуальность информации с точки зрения ориентации на насущные практические задачи',
            theoryPracticeRation: 'Оптимальное соотношение теории и практики',
            listenerOrientation: 'Ориентированность на запросы слушателей',
            materialClarity: 'Понятность и доступность изложенного материала',
            materialValue: 'Практическая ценность материала, применимость в работе'
          },
      futureInterestOptions: {
        theory: 'Теория образовательных измерений и классическая теория тестов',
        model: 'Модели многостадийных измерений в аккредитации специалистов здравоохранения',
        testForm: 'Формы тестовых заданий и технология их написаний',
        practiceForm: 'Формы практических заданий в аккредитации',
        cases: 'Кейсы в образовании, их возможность, виды и предназначение',
        statistics: 'Статистический анализ качества заданий',
        resInterpretation: 'Шкалирование и интерпретация результатов измерений',
        other: 'Другое'
      },
      workEstimate: {
        DeepKnowledge: 'Продемонстрировала глубокое знание материала курса',
        GoodExplanation: 'Грамотно и доступно излагала материал курса',
        GoodAttention: 'Уделила необходимое внимание ответам на мои вопросы',
        PersonalExperience: 'Использовала примеры из личного опыта / практики',
        Recommendation: 'Порекомендовали бы Вы этого преподавателя еще кому-либо?' +
            ' (да - поставьте 5, нет - поставьте 2)'
      },
      estimatePersonKeys: {
        verta: 'Малахова Татьяна Николаевна',
        beloborod: 'Белобородова Александра Владимировна',
        knyaz: 'Князева Светлана Анатольевна'
      },
      info: {
        type: 'studyRes',
        goalReach: '',
        processSatisfaction: '',
        infoNovelty: '',
        infoActuality: '',
        theoryPracticeRation: '',
        listenerOrientation: '',
        materialClarity: '',
        materialValue: '',
        effectiveness: '',
        competence: '',
        knowledgeUse: '',
        enhanceWish: '',
        futureInterest: [],
        futureInterestText: '',
        shareDesire: '',
        vertaGeneralGrade: '',
        vertaDeepKnowledge: '',
        vertaGoodExplanation: '',
        vertaGoodAttention: '',
        vertaPersonalExperience: '',
        vertaRecommendation: '',
        beloborodGeneralGrade: '',
        beloborodDeepKnowledge: '',
        beloborodGoodExplanation: '',
        beloborodGoodAttention: '',
        beloborodPersonalExperience: '',
        beloborodRecommendation: '',
        knyazGeneralGrade: '',
        knyazDeepKnowledge: '',
        knyazGoodExplanation: '',
        knyazGoodAttention: '',
        knyazPersonalExperience: '',
        knyazRecommendation: '',
        suggestion: '',
        teacherExperience: '',
        earlyExperience: '',
        sources: '',
        sourcesText: ''
      }
    }
  },
  computed: {
    ...mapGetters(['USER']),
  },
  methods: {
    async submitForm () {
      if (!this.$refs.studyResWorksheet.validate()) {
        return
      }
      const url = `${process.env.VUE_APP_SERVER_URL}/worksheet/register`
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        //worksheetId - это id мероприятия анкетирования (не путать с типом)
        body: JSON.stringify({ ...this.info,
          personId: this.USER.userId, worksheetId: this.$route.query.id }),
      });
      if (response.status === 200) {
        await this.$dialog.notify.success('Анкета сохранена', { timeout: 1500 })
      } else {
        await this.errorHandler(response, 'Ошибка при сохранении анкеты')
      }

    },
  }
}
</script>

<style scoped>

/*spacedEvenly - данный класс используется для дополнительной идентификации radio button, которые нужно выравнять
само выравнивание описано в main.css */

.centerAligment {
  align-items: center
}

</style>