<template>
  <v-container fill-height fluid>
      <router-view />
  </v-container>

</template>

<script>
export default {
  name: "AdminPanel",
  data () {
    return {
    }
  }
}
</script>

<style scoped>

</style>