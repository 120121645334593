<template>
  <v-card flat>
    <v-card-title>
      Список типов регистрации
    </v-card-title>
    <v-card-text>
      <v-data-table
          :headers="headers"
          :items="regTypes"
          :items-per-page="10"
          class="stripped"
          no-data-text="Нет данных"
      >
        <template #[`item.is_active`]="{item, value}">
          <v-simple-checkbox
              :value="value"
              @click="changeActivity(item)" />
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { dbMixin } from "@/mixins"

export default {
  name: "RegTypesPage",
  mixins: [dbMixin],
  data () {
    return {
      headers: [
        {
          value: 'is_active',
          text: 'Активный',
          width: '110px'
        },
        {
          value: 'name',
          text: 'Тип регистрации'
        }
      ],
      regTypes: [],
    }
  },
  beforeMount () {
    this.getCatalogData('regTypes')
  },
  methods: {
    async changeActivity (item) {
      item.is_active = !item.is_active
      const url = `${process.env.VUE_APP_SERVER_URL}/mutation/reg_type`
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: item.id, is_active: item.is_active }),
      });
      if (response.status !== 200) {
        await this.errorHandler(response, 'Ошибка сохранения')
      }

    }
  }

}
</script>

<style scoped>
.v-data-table .v-toolbar__content > .v-btn {
  margin-right: 10px;
}
</style>