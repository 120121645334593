<template>
  <v-form ref="accredWorksheet">
    <v-card
        max-width="640"
        class="mx-auto"
        flat
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-app-bar
                  dark
                  color="#4285F4"
                  height="10px"
              >
              </v-app-bar>
              <v-card-title class="text-h5">
                Анкета аккредитуемого
              </v-card-title>
              <v-card-subtitle class="errorColor">* - Обязательный вопрос</v-card-subtitle>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <div style="display: flex; align-items: flex-start">
                <span class="subtitle subtitle--width95">
                  На Ваш взгляд необходимость предварительной подготовки (работа с тестовыми сценариями
                  тренажера виртуальной реальности) является обязательной для успешного прохождения испытания?
                </span>
                <span class="subtitle subtitle--padded errorColor">*</span>
              </div>
              <v-card-text>
                <v-radio-group v-model="prep" :rules="[v => !!v]">
                  <v-radio
                      label="Да"
                      value="yes"
                  ></v-radio>
                  <v-radio
                      label="Нет"
                      value="no"
                  ></v-radio>
                  <v-radio
                      label="Другое"
                      value="other"
                  ></v-radio>
                  <v-text-field
                      v-if="prep === 'other'"
                      v-model="prepText"
                      placeholder="Другое"
                      :rules="[v => !!v]"
                  />
                </v-radio-group>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <div style="display: flex; align-items: flex-start">
                <span class="subtitle subtitle--width95">
                  Сколько времени потребовалось Вам для уверенной работы с тренажером?:
                </span>
                <span class="subtitle subtitle--padded errorColor">*</span>
              </div>
              <v-card-text>
                <v-radio-group
                    v-model="prepTime"
                    :rules="[v => !!v]">
                  <v-radio
                      label="45 минут"
                      value="45min"
                  />
                  <v-radio
                      label="90 минут"
                      value="90min"
                  />
                  <v-radio
                      label="больше 90 минут"
                      value="over90"
                  />
                  <v-radio
                      label="так и не освоился(ась)"
                      value="failedLearning"
                  />
                </v-radio-group>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <div style="display: flex; align-items: flex-start">
                <span class="subtitle subtitle--width95">
                  Оцените Ваши ощущения (Ваш комфорт) при прохождении сценариев
                  на тренажере виртуальной реальности:
                </span>
                <span class="subtitle subtitle--padded errorColor">*</span>
              </div>
              <v-card-text>
                <v-radio-group
                    v-model="feeling"
                    :rules="[v => !!v]">
                  <v-radio
                      label="Никаких неприятных ощущений не испытывал(а)"
                      value="good"
                  ></v-radio>
                  <v-radio
                      label="Развилось головокружение"
                      value="dizzy"
                  ></v-radio>
                  <v-radio
                      label="Развилась тошнота"
                      value="nausea"
                  ></v-radio>
                  <v-radio
                      label="Другое"
                      value="other"
                  ></v-radio>
                </v-radio-group>
                <v-text-field
                    v-if="feeling === 'other'"
                    v-model="feelingText"
                    placeholder="Другое"
                    :rules="[v => !!v]"
                />
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <div style="display: flex; align-items: flex-start">
                <span class="subtitle subtitle--width95">
                  Оцените по 5-балльной шкале интуитивно понятный интерфейс,
                  алгоритм действий при работе в режиме "Экзамена":
                </span>
                <span class="subtitle subtitle--padded errorColor">*</span>
              </div>
              <v-card-text>
                <v-radio-group
                    v-model="interfaceGrade"
                    :rules="[v => !!v]">
                  <v-radio
                      v-for="n in 5"
                      :key="`interfaceGrade-${n}`"
                      :label="`${n}`"
                      :value="n"
                  ></v-radio>
                </v-radio-group>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <div style="display: flex; align-items: flex-start">
                <span class="subtitle subtitle--width95">
                  Оцените эффективность обучения на тренажере в режиме "с подсказками"
                  в сценарии "0" по 5-балльной шкале:
                </span>
                <span class="subtitle subtitle--padded errorColor">*</span>
              </div>
              <v-card-text>
                <v-radio-group
                    v-model="effectiveness"
                    :rules="[v => !!v]">
                  <v-radio
                      v-for="n in 5"
                      :key="`effectiveness-${n}`"
                      :label="`${n}`"
                      :value="n"
                  ></v-radio>
                </v-radio-group>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <div style="display: flex; align-items: flex-start">
                <span class="subtitle subtitle--width95">
                  Оцените условную реалистичность симулированного приема врача с использованием тренажера
                  виртуальной реальности по 5-балльной шкале:
                </span>
                <span class="subtitle subtitle--padded errorColor">*</span>
              </div>
              <v-card-text>
                <v-radio-group
                    v-model="docMethod"
                    :rules="[v => !!v]">
                  <v-radio
                      v-for="n in 5"
                      :key="`docMethod-${n}`"
                      :label="`${n}`"
                      :value="n"
                  ></v-radio>
                </v-radio-group>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <div style="display: flex; align-items: flex-start">
                <span class="subtitle subtitle--width95">
                  Оцените эффективность тренажера виртуальной реальности для формирования профессиональных
                  компетенций врача-дерматовенеролога по 5-балльной шкале:
                </span>
                <span class="subtitle subtitle--padded errorColor">*</span>
              </div>
              <v-card-text>
                <v-radio-group
                    v-model="venera"
                    :rules="[v => !!v]">
                  <v-radio
                      v-for="n in 5"
                      :key="`venera-${n}`"
                      :label="`${n}`"
                      :value="n"
                  ></v-radio>
                </v-radio-group>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <div style="display: flex; align-items: flex-start">
                <span class="subtitle subtitle--width95">
                  Готовы ли Вы в будущем проходить оценку навыков/знаний на тренажере виртуальной
                  реальности?
                </span>
                <span class="subtitle subtitle--padded errorColor">*</span>
              </div>
              <v-card-text>
                <v-radio-group
                    v-model="readiness"
                    :rules="[v => !!v]">
                  <v-radio
                      label="Да"
                      value="yes"
                  ></v-radio>
                  <v-radio
                      label="Нет"
                      value="no"
                  ></v-radio>
                  <v-radio
                      label="Другое"
                      value="other"
                  ></v-radio>
                  <v-text-field
                      v-if="readiness === 'other'"
                      v-model="readinessText"
                      placeholder="Другое"
                      :rules="[v => !!v]"
                  />
                </v-radio-group>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <v-card-subtitle>
                <span style="color: black !important">
                  "Ваш результат проведенной аттестации (в условиях пилотного проекта)
                </span>
              </v-card-subtitle>
              <v-card-text>
                <v-radio-group v-model="result">
                  <v-radio
                      label="Сдано"
                      value="successTest"
                  ></v-radio>
                  <v-radio
                      label="Не сдано"
                      value="failedTest"
                  ></v-radio>
                </v-radio-group>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <v-card-subtitle>
                <span style="color: black !important">
                Ваши замечания / предложения по улучшению сценариев тренажера виртуальной реальности:
                </span>
              </v-card-subtitle>
              <v-card-text>
                <v-text-field v-model="scenarioSuggestion" label="Мой ответ"></v-text-field>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <v-card-subtitle>
                <span style="color: black !important">
                Ваши замечания/предложения по улучшению процедуры использования тренажера виртуальной реальности:
                </span>
              </v-card-subtitle>
              <v-card-text>
                <v-text-field v-model="procedureSuggestion" label="Мой ответ"></v-text-field>
              </v-card-text>
            </v-card>
          </v-col>

        </v-row>
      </v-container>

      <v-btn color="primary" class="worksheetSubmitBtn" @click="submitForm">Отправить</v-btn>
    </v-card>
  </v-form>
</template>

<script>
import { formsMixin } from "@/mixins"
import { mapGetters } from "vuex"

export default {
  name: 'AccredWorksheet',
  mixins: [formsMixin],
  data () {
    return {
      type: 'accred',
      prep: '',
      prepText: '',
      prepTime: '',
      feeling: '',
      feelingText: '',
      interfaceGrade: 0,
      effectiveness: 0,
      docMethod: 0,
      venera: '',
      readiness: '',
      readinessText: '',
      result: '',
      scenarioSuggestion: '',
      procedureSuggestion: ''
    }
  },
  computed: {
    ...mapGetters(['USER']),
  },
  methods: {
    async submitForm () {
      if (!this.$refs.accredWorksheet.validate()) {
        return
      }
      const url = `${process.env.VUE_APP_SERVER_URL}/worksheet/register`
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({...this.$data, personId: this.USER.userId, worksheetId: this.$route.query.id }),
      });
      if (response.status === 200) {
        await this.$dialog.notify.success('Анкета сохранена', { timeout: 1500 })
      } else {
        await this.errorHandler(response, 'Ошибка при сохранении анкеты')
      }

    }
  }
}


</script>

<style scoped>
.questionTitle {
  line-height: 24px;
  color: #202124;
  font-weight: 400;
  font-size: 12pt;
  font-family: 'docs-Roboto', Helvetica, Arial, sans-serif;
}
</style>

