import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#0054a5',
        secondary: '#6d6e71',
        accent: '#043377',
        error: '#E53935',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#ef9013'
      }
    },
  },
})

export default vuetify