<template>
  <v-card flat>
    <slot name="cardTitle"></slot>
    <v-card-text>
      <v-form ref="form">
        <v-autocomplete
            v-model="regInfo.regionSubjectId"
            :items="regions"
            item-value="code"
            item-text="name"
            :rules="[v => !!v]"
            label="Субъект (Регион)"
            no-data-text="Нет данных"
        ></v-autocomplete>
        <v-select
            v-model="regInfo.subordinateTypeId"
            :items="subordinateTypes"
            item-value="id"
            item-text="name"
            :rules="[v => !!v]"
            label="Подведомственность организации"
            no-data-text="Нет данных"
        ></v-select>
        <v-autocomplete
            v-model="regInfo.organizationId"
            :items="organizations"
            item-value="code"
            item-text="name"
            :rules="[v => !!v]"
            label="Организация"
            no-data-text="Организаций не найдено"
        ></v-autocomplete>
        <person-card v-if="!loadedInfo" ref="personCard"></person-card>
        <v-text-field v-model="regInfo.position" label="Занимаемая должность" :rules="[v => !!v]"/>
        <v-row>
          <v-col cols="4">
            <v-checkbox v-model=regInfo.studied2019 label="Проходил ли обучение в 2019 г." />
            <v-checkbox v-model=regInfo.studied2020 label="Проходил ли обучение в 2020 г." />
            <v-checkbox v-model=regInfo.studied2021 label="Проходил ли обучение в 2021 г." />
            <v-checkbox v-model=regInfo.studied2022 label="Проходил ли обучение в 2022 г." />
            <v-checkbox v-model=regInfo.studied2023 label="Проходил ли обучение в 2023 г." />
          </v-col>
          <v-col cols="4">
            <v-checkbox v-model=regInfo.patient2019 label="Участие в качестве СП в аккредитации в 2019 г." />
            <v-checkbox v-model=regInfo.patient2020 label="Участие в качестве СП в аккредитации в 2020 г." />
            <v-checkbox v-model=regInfo.patient2021 label="Участие в качестве СП в аккредитации в 2021 г." />
            <v-checkbox v-model=regInfo.patient2022 label="Участие в качестве СП в аккредитации в 2022 г." />
            <v-checkbox v-model=regInfo.patient2023 label="Участие в качестве СП в аккредитации в 2023 г." />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions v-if="showCardActions">
      <v-spacer />
      <v-btn
          outlined
          color="primary"
          @click="close"
      >
        Отмена
      </v-btn>
      <v-btn
          outlined
          color="primary"
          @click="save"
      >
        Сохранить
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import PersonCard from "@/components/forms/PersonCard"
import { dateMixin, dbMixin, formsMixin, registerMixin } from "@/mixins"

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Stdpatient",
  components: { PersonCard },
  mixins: [dbMixin, dateMixin, registerMixin, formsMixin],
  data () {
    return {
      menu: false,
      role: 'stdpatient',
      organizations: [],
      subordinateTypes: [],
      regions: [],
      regInfo: {
        subordinateTypeId: '',
        organizationId: '',
        regionSubjectId: '',
        surname: '',
        firstname: '',
        patronymic: '',
        phone: '',
        email: '',
        position: '',
        studied2019: false,
        studied2020: false,
        studied2021: false,
        studied2022: false,
        studied2023: false,
        patient2019: false,
        patient2020: false,
        patient2021: false,
        patient2022: false,
        patient2023: false
      }
    }
  },
  beforeMount () {
    this.getCatalogData('regions')
    this.getCatalogData('organizations')
    this.getCatalogData('subordinateTypes')
  },
  methods: {
    saveMenu (date) {
      // Метод позовляет закрывать date-picker сразу после выбора даты
      this.$refs.menu.save(date)
    },
    formatDate (dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;

      return formattedDate
    }
  }
}
</script>

<style scoped>

</style>