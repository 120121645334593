<template>
  <v-card flat>
    <v-card-title>Результаты анкетирования</v-card-title>
    <v-card-text>
      <div style="width: 30%">
        <v-select
            v-model="fileType"
            :items="worksheets"
            return-object>
        </v-select>
      </div>
      <v-btn color="primary" @click="download" :disabled="!fileType">
        Скачать
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>

import { saveAs } from 'file-saver'

export default {
  name: "WorksheetResults",
  data () {
    return {
      fileType: '',
      worksheets: [
        { value: 'expertWorksheet', text: 'Анкеты экспертов' },
        { value: 'accredWorksheet', text: 'Анкеты аккредитуемых' },
        { value: 'anReanimWorksheet', text: 'Анестизиология-реаниматология' },
        { value: 'studyResWorksheet', text: 'Анкеты по итогам обучения' },
        { value: 'accredStationWorksheet', text: 'Анкеты для аккредитационных площадок' },
        { value: 'additionalWebinarWorksheet', text: 'Анкета вебинара 28.09.2023' }
      ]
    }
  },
  methods: {
    download () {
      const url = `${process.env.VUE_APP_SERVER_URL}/excel/worksheet_results?fileType=${this.fileType.value}`
      saveAs(url, `${this.fileType.text}.xlsx`)
    }
  }
}
</script>