<template>
  <div>
    <v-text-field label="Фамилия" v-model="surname" :rules="[v => !!v]" />
    <v-text-field label="Имя" v-model="firstname" :rules="[v => !!v]" />
    <v-text-field label="Отчество" v-model="patronymic" />
    <v-text-field label="Электронная почта" v-model="email" type="email" :rules="[emailRule]" />
    <v-text-field label="Контактный телефон (для мобильной связи)" v-model="phone" :rules="[phoneRule]"/>
  </div>
</template>

<script>
export default {
  name: "PersonCard",
  props: {
    personalInfo: {
      type: Object
    }
  },
  data () {
    return {
      surname: '',
      firstname: '',
      patronymic: '',
      email: '',
      phone: ''
    }
  },
  watch: {
    personalInfo: {
      handler (data) {
        for (let key in data) {
          this[key] = data[key]
        }
      }
    }
  },
  methods: {
    emailRule (value) {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      if (!value || !regex.test(value)) {
        return 'Некорретно введена электронная почта'
      } else {
        return true
      }
    },
    phoneRule (value) {
      const regex = /^[ \d-+]+$/
      if (!value || !regex.test(value)) {
        return 'Некорретно введен телефон'
      } else {
        return true
      }
    }
  }
}
</script>
