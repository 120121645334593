<template>
  <!--  <component v-if=role :is="role" />-->
  <v-card flat>
    <v-tabs
        v-model="tab"
        icons-and-text
    >

      <v-tab
          key="personalData">
        Личные данные
        <v-icon>mdi-account</v-icon>
      </v-tab>

      <v-tab key="profData">
        Профессиональные данные
        <v-icon>mdi-briefcase</v-icon>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
          key="personalData"
      >
        <v-card flat>
          <v-card-text>
            <v-form ref="profileForm">
              <v-container fluid>
                <v-row no-gutters>
                  <v-col xl="6" lg="8" md="8">
                    <person-card ref="personCard" :personal-info="personalInfo" />
                    <v-row>
                      <v-col>
                        <v-text-field
                            v-model="password"
                            autocomplete="new-password"
                            label="Введите пароль"
                            :type="showPassword ? 'text' : 'password'"
                            :prepend-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                            @click:prepend="showPassword = !showPassword"
                            :rules="[v => !!v || 'Пароль не может быть пустым']"

                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                            v-model="passwordConfirm"
                            label="Подтвердите пароль"
                            :type="showPasswordConf ? 'text' : 'password'"
                            :prepend-icon="showPasswordConf ? 'mdi-eye-off' : 'mdi-eye'"
                            @click:prepend="showPasswordConf = !showPasswordConf"
                            :rules="[passwordValidation]"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn style="margin-left:16px" color="primary" @click="saveProfile">
              Сохранить данные
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
      <v-tab-item
          key="profData"
      >
        <v-card flat>
          <v-card-text>
            <!--            padding 16px - паддинг, который добавляет тег card-text-->
            <v-select
                v-model="chosenRole"
                prepend-icon="mdi-human"
                :items="roles"
                item-text="name"
                item-value="code"
                label="Выберите роль"
                no-data-text="Нет доступных ролей"
                dense
                style="padding: 16px 16px 0 16px"
            ></v-select>
            <form-chooser v-if="chosenRole" ref="chooser" :form-name="chosenRole" :show="true"
                          :loaded-info="professionalInfo[chosenRole]" />
          </v-card-text>
          <v-card-actions>
            <v-btn v-if="chosenRole" style="margin-left:16px" color="primary" @click="saveProfData">
              Сохранить данные
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>

import * as formNames from "@/components/forms";
import { mapState, mapGetters } from "vuex"
import { PersonCard } from "@/components/forms"
import FormChooser from "@/components/FormChooser"
import { dbMixin } from "@/mixins"

export default {
  name: 'ProfilePage',
  components: { PersonCard, FormChooser, ...formNames },
  mixins: [dbMixin],
  data () {
    return {
      chosenRole: '',
      personalInfo: {},
      professionalInfo: {},
      password: '',
      passwordConfirm: '',
      showPassword: false,
      showPasswordConf: false,
      tab: null
    }
  },
  computed: {
    ...mapGetters(['USER_ROLES', 'USER']),
    ...mapState(['rolesMap']),
    roles () {
      const tmpRoles = this.USER_ROLES.map((el) => {
        if (el.code !== 'admin') {
          const newElement = { ...el }
          newElement.code = this.rolesMap[el.code]
          return newElement
        }
      })
      return tmpRoles
    }
  },
  async beforeMount () {
    const url = `${process.env.VUE_APP_SERVER_URL}/profile/personal?`
    let response = await fetch(url + new URLSearchParams({ userId: this.USER.userId }));
    if (response.ok) { // если HTTP-статус в диапазоне 200-299
      let info = await response.json();
      info = info[0]
      this.personalInfo = { ...info }
    } else {
      alert("Ошибка HTTP: " + response.status);
    }

    await this.getProfessionalData()
  },
  mounted () {
    const role = this.USER_ROLES[0].code
    this.chosenRole = role === 'admin' ? '' : this.rolesMap[role]
  },
  methods: {
    async getProfessionalData () {
      const url = `${process.env.VUE_APP_SERVER_URL}/profile/professional?`
      const roles = this.USER_ROLES.map(el => el.code)
      let response = await fetch(url + new URLSearchParams({ userId: this.USER.userId, roles }));

      if (response.ok) { // если HTTP-статус в диапазоне 200-299
        let info = await response.json();
        info = this.convertKeysToCamelCase(info)
        Object.keys(info).forEach(key => {
          info[key] = this.convertKeysToCamelCase(info[key])
        })
        this.professionalInfo = { ...info }
      } else {
        alert("Ошибка HTTP: " + response.status);
      }
    },
    passwordValidation (value) {
      if (value !== this.password) {
        return 'Пароли не совпадают'
      }
      return true
    },
    async saveProfile () {
      if (!this.$refs.profileForm.validate()) {
        return
      }
      const url = `${process.env.VUE_APP_SERVER_URL}/profile/personal/update`
      const response = await fetch(url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          phone: this.phone, ...this.$refs.personCard.$data,
          uid: this.USER.userId,
          password: this.password
        }),
      });
      if (response.status === 200) {
        await this.$dialog.notify.success('Данные сохранены успешно')
      } else {
        alert('Ошибка')
      }
    },
    async saveProfData () {
      if (this.$refs.chooser.$refs.chosenDialog.$refs.form.validate()) {
        const url = `${process.env.VUE_APP_SERVER_URL}/profile/professional/update`
        let req_data = { ...this.$refs.chooser.$refs.chosenDialog.$data.regInfo }
        req_data.role = this.chosenRole
        req_data.userId = this.USER.userId
        delete req_data['surname']
        delete req_data['firstname']
        delete req_data['patronymic']
        delete req_data['phone']
        delete req_data['email']
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...req_data
          }),
        });
        if (response.status === 200) {
          await this.$dialog.notify.success('Данные сохранены успешно')
        } else {
          let text = await response.text()
          if (!text) {
            text = 'Ошибка'
          }
          alert(text)
        }
      } else {
        await this.$dialog.error({ text: 'Заполните обязательные поля', title: 'Недостаточно данных', width: 400 })
      }
    }
  }
}
</script>