<template>
  <v-menu v-model="menu" :close-on-content-click="false">
    <template #activator="{ on: onMenu }">
      <v-tooltip bottom>
        <template #activator="{ on: onTooltip }">
          <v-badge
              :value="filtersEnabledCount > 0"
              :content="filtersEnabledCount"
              overlap
          >
            <div v-on="onTooltip">
              <v-btn
                  v-on="onMenu"
                  color="primary"
                  outlined
              >
                <v-icon>mdi-filter</v-icon>
              </v-btn>
            </div>
          </v-badge>
        </template>
        Фильтр
      </v-tooltip>
    </template>

    <v-card min-width="300">
      <v-card-text @keyup.enter="save">
        <v-list dense>
          <template v-for="(filter, index) in filters">
            <v-list-item
                v-if="filter.type !== 'hidden'"
                :key="index"
                dense
                class="ma-0 pa-0"
            >
              <slot :name="`filter-${index}`">
                <v-autocomplete
                    v-if="filter.type === 'select'"
                    :label="filter.label"
                    dense
                    clearable
                    :multiple="filter.multiple || false"
                    :chips="filter.multiple || false"
                    :deletable-chips="filter.multiple || false"
                    v-model="filter.value"
                    :items="filter.items"
                />
                <v-text-field
                    v-if="filter.type === 'text'"
                    :label="filter.label"
                    clearable
                    dense
                    v-model="filter.value"
                />
                <v-text-field
                    v-if="filter.type === 'number'"
                    :label="filter.label"
                    clearable
                    dense
                    type="number"
                    v-model="filter.value"
                />
                <date-range
                    v-if="filter.type === 'dateRange'"
                    :label="filter.label"
                    clearable
                    dense
                    v-model="filter.value"
                />
                <bool-select
                    v-if="filter.type === 'boolean'"
                    :label="filter.label"
                    clearable
                    dense
                    v-model="filter.value"
                />
              </slot>
            </v-list-item>
          </template>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-btn text depressed color="red" @click="cleanFilters">
          Очистить
        </v-btn>
        <v-spacer />
        <v-btn text depressed color="success" @click="save">
          Применить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { klona } from 'klona/json'
import BoolSelect from "@/components/common/BoolSelect"

export default {
  name: 'DataTableFilter',
  components: { BoolSelect },
  props: {
    value: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      menu: false,
      filters: klona(this.value)
    }
  },
  computed: {
    filtersEnabledCount () {
      return Object.keys(this.filters).filter(filter => this.filters[filter].type !== 'hidden' &&
          !this.isEmpty(this.filters[filter].value)).length
    }
  },
  watch: {
    value: {
      deep: true,
      handler (newVal) {
        if (newVal !== this.filters) {
          this.filters = klona(newVal)
        }
      }
    }
  },
  beforeMount () {
    const savedData = this.$store.getters.GET_SAVED_TABLE(this.$route)
    if (this.filters && savedData && savedData.filters) {
      Object.keys(this.filters).forEach((key) => {
        // eslint-disable-next-line vue/no-mutating-props
        this.filters[key].value = savedData.filters[key]
      })
    }
  },
  methods: {
    save () {
      this.$emit('input', this.filters)
      this.menu = false
    },
    isEmpty (value) {
      return value == null || value === 'undefined' || value === '' || value.length === 0
    },
    cleanFilters () {
      Object.keys(this.filters).forEach((key) => {
        if (this.filters[key].value && this.filters[key].value.constructor === Array) {
          // eslint-disable-next-line vue/no-mutating-props
          this.filters[key].value = []
        } else {
          // eslint-disable-next-line vue/no-mutating-props
          this.filters[key].value = null
        }
      })
      this.$store.commit('CLEAN_TABLE', {
        route: this.$route,
        filters: true
      })
    }
  }
}
</script>

<style scoped>

</style>
