import { mapMutations } from 'vuex'

export default {
  watch: {
    filters: {
      deep: true,
      handler (data) {
        this.SAVE_TABLE({
          tableName: this.$route.name,
          data: JSON.parse(JSON.stringify(data))
        })
      }
    }
  },
  beforeMount () {
    const savedData = this.$store.getters.GET_SAVED_TABLE(this.$route.name)
    if (savedData) {
      this.filters = savedData.filter
    }
  },
  methods: {
    ...mapMutations(['SAVE_TABLE'])
  }
}
