const dbMixin = {
  data () {
    return {}
  },
  methods: {
    close () {
      this.$emit('close')
    },
    async save () {
      // Сохранении данных при регистрации в системе
      if (this.$refs.form.validate()) {
        // Копирование личных данных в общие данные
        if (this.$refs.personCard) {
          Object.keys(this.$refs.personCard.$data).forEach((key) => {
            // eslint-disable-next-line no-prototype-builtins
            if (this.regInfo.hasOwnProperty(key)) {
              this.regInfo[key] = this.$refs.personCard.$data[key];
            }
          });
        }
        const email_url = `${process.env.VUE_APP_SERVER_URL}/registration/check_email?email=${this.regInfo.email}`
        let email_check = await fetch(email_url)
        email_check = await email_check.json()

        // Провека есть ли пользователь с таким email в базе
        if (email_check) {
          const title = `В системе найден пользователь ${email_check.fullname} с такой же электронной почтой`
          const text = `Для добавления новой роли введите свой пароль или 123456`
          const password = await this.$dialog.prompt({
            title,
            text,
            textField: {
              type: 'password',
            },
            actions: {
              false: 'Отмена',
              true: {
                text: 'Продолжить регистрацию',
                color: 'primary'
              }
            }

          })
          // Проверка пароля
          if (password) {
            const username = this.regInfo.email
            const checkUserExists = true
            const url = `${process.env.VUE_APP_SERVER_URL}/auth/login`
            let response = await fetch(url, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ username, password, checkUserExists }),
            });
            if (response.ok) {
              await this.$dialog.notify.success('Идентификация прошла успешно', { timeout: 1200 })
            } else {
              await this.$dialog.notify.error('Неверный пароль', { timeout: 1500 })
              return
            }
          } else return
        }

        const url = `${process.env.VUE_APP_SERVER_URL}/registration/registration`
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ role: this.role, ...this.regInfo }),
        });
        if (response.status === 200) {
          const data = await response.json()

          if (data.extra_info) {
            await this.$dialog.warning({
              text: data.extra_info, actions: {
                true: {
                  text: 'Завершить регистрацию',
                  color: 'primary'
                }
              }
            })
          }
          await this.$dialog.notify.success('Регистрация выполнена успешно', { timeout: 1500 })
          this.$emit('close')
        } else {
          await this.errorHandler(response, 'При регистрации произошла ошибка')
        }

      } else {
        await this.$dialog.error({ text: 'Заполните обязательные поля', title: 'Недостаточно данных', width: 400 })
      }
      this.$emit('refetch') // Нужен не для всех карточек
    }
  }
}


export default dbMixin;