<template>
  <v-card flat>
    <v-card-title>
      Первичная аккредитация_сведения
    </v-card-title>
    <v-card-text>
      <v-dialog
          :value="dialog"
          persistent>
        <accred-info
            v-if="dialog"
            ref="addEdit"
            @refetch="refetch"
            :show-card-actions="true"
            @close="close" />
      </v-dialog>
      <v-data-table
          :headers="headers"
          :items="accredInfo"
          :items-per-page="10"
          class="stripped"
          no-data-text="Нет данных"
      >
        <template #top>
          <v-toolbar flat dense>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    @click="downloadExcel">
                  <v-icon>mdi-file-excel</v-icon>
                </v-btn>
              </template>
              Скачать таблицу
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    @click="refetch"
                >
                  <v-icon>mdi-sync</v-icon>
                </v-btn>
              </template>
              Обновить
            </v-tooltip>
            <v-btn
                color="primary"
                outlined
                @click="clickAddEditAccredInfo()"
            >
              Добавить
              <v-icon dark right>
                mdi-plus-thick
              </v-icon>
            </v-btn>
          </v-toolbar>
        </template>
        <template #[`item.accred2024`]="{value}">
          {{boolConverter(value)}}
        </template>
        <template #[`item.actions`]="{ item }">
          <template>
            <v-btn
                icon
                @click="clickAddEditAccredInfo(item)"
                title="Редактировать"
                color="primary"
            >
              <v-icon>
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
          <v-btn
              icon
              @click="confirmDelete(item)"
              title="Удалить"
              color="error"
          >
            <v-icon>
              mdi-delete
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { dateMixin, dbMixin } from "@/mixins"
import { saveAs } from "file-saver"
import AccredInfo from "@/components/forms/AccredInfo"

export default {
  name: "AccredInfoPage",
  mixins: [dbMixin, dateMixin],
  components: { AccredInfo },
  data () {
    return {
      dialog: false,
      isMounted: false,
      headersCopy: [],
      headers: [
        {
          value: 'actions',
          text: 'Действия',
          width: '105px',
          sortable: false
        },
        {
          value: 'regionName',
          text: 'Субъект',
          width: '125px',
        },
        {
          value: 'subName',
          text: 'Подведомственность',
        },
        {
          value: 'orgName',
          text: 'Наименование образовательной организации',
        },
        {
          value: 'orgBossFullname',
          text: 'ФИО ответственного от образовательной организации за проведение первичной аккредитации',
        },
        {
          value: 'orgBossPosition',
          text: 'Занимаемая должность ответственного от образовательной организации за проведение первичной аккредитации',
        },
        {
          value: 'orgBossEmail',
          text: 'Адрес электронной почты (актуальный) ответственного от образовательной организации за проведение первичной аккредитации',
        },
        {
          value: 'orgBossPhone',
          text: 'Контактный телефон (для мобильной связи) ответственного от образовательной организации за проведение первичной аккредитации',
        },

        {
          value: 'simCenterBossFullname',
          text: 'Фамилия, имя, отчество руководителя аккредитационно-симуляционного центра образовательной организации',
        },
        {
          value: 'simCenterBossPosition',
          text: 'Должность руководителя аккредитационно-симуляционного центра образовательной организации',
        },
        {
          value: 'simCenterBossEmail',
          text: 'Адрес электронной почты руководителя аккредитационно-симуляционного центра образовательной организации',
        },
        {
          value: 'simCenterBossPhone',
          text: 'Контактный телефон (для мобильной связи) руководителя аккредитационно-симуляционного центра' +
              ' образовательной организации',
        },
        {
          value: 'accred2024',
          text: 'Проведение первичной аккредитации на базе АЦ Вашей организации в 2025 году'
        },
        {
          value: 'gStudentCount',
          text: 'Общее количество выпускников, завершающих освоение основных профессиональных' +
              ' образовательных программ высшего медицинского образования и высшего фармацевтического образования' +
              ' - программ бакалавриата, программ специалитета - в 2025 г.'
        }
      ],
      accredInfo: [],
    }
  },
  async beforeMount () {
    await this.refetch()
    this.headersCopy = this.headers.slice()
    this.refetchHeaders()
    this.isMounted = true
  },
  methods: {
    refetchHeaders () {
      // Обновляем список столбцов, взависимости от специальностей, который прикреплены к площадке.
      // У тех записей, у которых нет данных для столбца будет пустота
      if (this.isMounted) {
        // В beforeMount не нужно повторно копировать.
        // А вот при добавлении/редактированни записей нужно, т.к могут появится новые столбцы, но добавлять их нужно
        // к исходному списку, а не к уже измененному
        this.headers = this.headersCopy.slice()
      }
      for (let key in this.accredInfo[0]) {
        if (this.hasRussianLetter(key)) {
          this.headers.push({ value: key, text: key })
        }
      }
    },
    hasRussianLetter (str) {
      const russianLetters = /[а-яА-Я]/
      return russianLetters.test(str)
    },
    async downloadExcel () {
      const url = `${process.env.VUE_APP_SERVER_URL}/excel/accred_info`
      saveAs(url, 'Первичная аккредитация_сведения.xlsx')
    },
    clickAddEditAccredInfo (item) {
      this.openAddEditDialog(item)
    },
    async confirmDelete (item) {
      if (await this.$dialog.confirm({
        text: `Вы действительно хотите удалить запись?`
      })) {
        this.delete(item)
      }
    },
    async delete (item) {
      const url = `${process.env.VUE_APP_SERVER_URL}/mutation/accred_info`
      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: item.organizationId }),
      });
      if (response.status == 200) {
        this.$dialog.notify.success("Запись удалена", { dismissible: false, timeout: 1000 })
        this.refetch()
      } else {
        await this.errorHandler(response, 'Ошибка при удалении')
      }
    },
    async refetch () {
      const url = `${process.env.VUE_APP_SERVER_URL}/catalog/accred_info`
      const info = []
      let response = await fetch(url);
      if (response.ok) { // если HTTP-статус в диапазоне 200-299
        // получаем тело ответа (см. про этот метод ниже)
        let items = await response.json();
        items.forEach((el) => {
          el = this.convertKeysToCamelCase(el)
          info.push(el)
        })
        this.accredInfo = info.slice()
        if (this.isMounted) {
          // Условие нужно для того, чтобы не вызывать этот метод, когда refetch был вызвал из beforeMount,
          // т.к он там и так вызывается
          this.refetchHeaders()
        }
      } else {
        alert("Ошибка HTTP: " + response.status);
      }
    },
    openAddEditDialog (item) {
      this.dialog = true
      if (item) {
        this.$nextTick(() => {
          this.loadInfoToRefCard(item)
        })
      }
    },
    async loadInfoToRefCard (item) {
      for (let key in this.$refs.addEdit.regInfo) {
        if (key !== 'specialitiesCount') {
          this.$refs.addEdit.regInfo[key] = item[key]
        }
      }
      this.$refs.addEdit.mode = 'edit'
      const specInfo = await this.getSpecialitiesCount()

      this.$refs.addEdit.regInfo.specialitiesCount = []
      specInfo.forEach((el) => {
        const info = {...el}
        this.$refs.addEdit.regInfo.specialitiesCount.push(info)
      })
      if (specInfo[0]?.year) {
        this.$refs.addEdit.regInfo.year = specInfo[0].year
      }
    },
    async getSpecialitiesCount () {
      const organizationId = this.$refs.addEdit.regInfo.organizationId
      const url = `${process.env.VUE_APP_SERVER_URL}/extra/accred_info_spec?organization_id=${organizationId}`
      let specInfo = await fetch(url)
      specInfo = await specInfo.json()
      return specInfo
    },
    close () {
      this.dialog = false
    }
  }

}
</script>

<style scoped>
.v-data-table .v-toolbar__content > .v-btn {
  margin-right: 10px;
}
</style>