<template>
  <v-dialog
      max-width="800px"
      :value="dialog"
      persistent
  >
    <v-card>
      <v-card-text>
        <v-card-title>
          {{ title }} вебинара
        </v-card-title>
        <v-form>
          <v-text-field v-model="webinar.name" label="Название вебинара" />
          <v-menu
              v-model="menu"
              ref="menu"
              :close-on-content-click="false"
              :return-value.sync="webinar.date"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="webinar.date"
                  label="Дата"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              />
            </template>
            <v-date-picker
                v-model="webinar.date"
                no-title
                scrollable
                locale="ru"
            >
              <v-spacer></v-spacer>
              <v-btn
                  text
                  color="primary"
                  @click="menu = false"
              >
                Отмена
              </v-btn>
              <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(webinar.date)"
              >
                ОК
              </v-btn>
            </v-date-picker>
          </v-menu>
<!--          d-flex - помогает ограничить чекбокс, чтобы нажатии обрабатывалось только при точном нажатии-->
          <div class="d-flex">
          <v-checkbox v-model="webinar.isActual" label="Актуальный" />
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
            outlined
            color="primary"
            @click="close"
        >
          Отмена
        </v-btn>
        <v-btn
            outlined
            color="primary"
            @click="save"
        >
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { dateMixin, dbMixin } from "@/mixins"

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Webinar",
  mixins: [dbMixin, dateMixin],
  data () {
    return {
      dialog: false,
      menu: false,
      mode: '',
      webinar: {
        id: '',
        name: '',
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        isActual: true
      }
    }
  },
  computed: {
    title () {
      return this.mode === 'add' ? 'Добавление' : 'Редактирование'
    }
  },
  methods: {
    myClick() {
      this.webinar.isActual = !this.webinar.isActual
    },
    close () {
      this.dialog = false
      this.clearFields()
    },
    clearFields () {
      this.webinar.name = ''
      this.webinar.date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      this.webinar.isActual = true
    },
    openAddEditDialog (item) {
      this.dialog = true
      if (item) {
        this.mode = 'edit'
        this.webinar = { ...item }
        this.webinar.date = this.pickerDate(this.webinar.date)
      } else {
        this.mode = 'add'
      }
    },
    async save () {
      const url = `${process.env.VUE_APP_SERVER_URL}/mutation/webinar`
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ mode: this.mode, ...this.webinar }),
      });
      if (response.status == 200) {
        const action = this.mode === 'add' ? 'добавлен' : 'отредактирован'
        this.$dialog.notify.success(`Вебинар успешно ${action}`, { dismissible: false, timeout: 1000 })
        this.close()
        this.$emit('refetch')
      } else {
        await this.errorHandler(response, 'Ошибка обработки вебинара')
      }

    }
  }
}
</script>

<style scoped>

</style>