<template>
  <v-card flat>
    <slot name="cardTitle"></slot>
    <v-card-text>
      <v-form ref="form">
        <v-text-field
            v-model="regInfo.orgName"
            label="Наименование организации"
            readonly />
        <v-autocomplete
            v-model="regInfo.regionSubjectId"
            :items="regions"
            item-value="code"
            item-text="name"
            :rules="[v => !!v]"
            label="Субъект"
            no-data-text="Нет данных"
        ></v-autocomplete>
        <v-select
            v-model="regInfo.subordinateTypeId"
            :items="subordinateTypes"
            item-value="id"
            item-text="name"
            :rules="[v => !!v]"
            label="Подведомственность"
            no-data-text="Нет данных"
        ></v-select>

<!--        <v-text-field-->
<!--            readonly-->
<!--            label="Фамилия, имя, отчество ответственного от образовательной организации за проведение первичной аккредитации"-->
<!--            v-model="regInfo.orgBossFullname" :rules="[v => !!v]" />-->
<!--        <v-text-field-->
<!--            label="Адрес электронной почты (актуальный) ответственного от образовательной организации за проведение первичной аккредитации"-->
<!--            v-model="regInfo.orgBossEmail" type="email" :rules="[emailRule]" />-->
<!--        <v-text-field-->
<!--            label="Контактный телефон (для мобильной связи) ответственного от образовательной организации за проведение первичной аккредитации"-->
<!--            v-model="regInfo.orgBossPhone" :rules="[v => !!v]" />-->
        <v-text-field v-model="regInfo.orgBossPosition"
                      label="Занимаемая должность ответственного от образовательной организации
                       за проведение первичной аккредитации"
                      :rules="[v => !!v]" />
        <v-text-field
            v-model="regInfo.simCenterBossFullname"
            :rules="[v => !!v]"
            label="Фамилия, имя, отчество руководителя аккредитационно-симуляционного центра образовательной
             организации"
        />
        <v-text-field
            v-model="regInfo.simCenterBossEmail"
            type="email"
            :rules="[emailRule]"
            label="Адрес электронной почты руководителя аккредитационно-симуляционного центра образовательной
             организации"
        />
        <v-text-field
            v-model="regInfo.simCenterBossPhone"
            :rules="[v => !!v]"
            label="Контактный телефон (для мобильной связи) руководителя аккредитационно-симуляционного центра
             образовательной организации"
        />

        <v-text-field v-model="regInfo.simCenterBossPosition"
                      :rules="[v => !!v]"
                      label="Должность руководителя аккредитационно-симуляционного центра образовательной
                       организации" />
        <v-select
            v-model="regInfo.accred2024"
            :rules="[v => v !== '']"
            label="Проведение первичной аккредитации на базе АЦ Вашей организации в 2024 году"
            :items="[{text: 'Да', value: true}, {text: 'Нет', value: false}]"
        />
        <v-text-field v-model="regInfo.year"
                      label="Год"
                      :min="2024"
                      type="number"
                      readonly
        />
        <v-text-field
            v-model="regInfo.gStudentCount"
            readonly
            disabled
            :label="'Общее количество выпускников, ' +
             'завершающих освоение основных профессиональных образовательных программ высшего' +
             'медицинского образования и высшего фармацевтического образования - программ бакалавриата,' +
              `программ специалитета - в ${regInfo.year} г.`" />
        <v-select
            v-model="regInfo.specialitiesCount"
            :items="firstAccredSpecialities"
            item-text="name"
            item-value="code"
            multiple
            chips
            return-object
            label="Специальность, выпускники (кол-во)"
            :rules="[v => v.length > 0]" />
        <div class="inlineBlocks" v-for="(row, index) in regInfo.specialitiesCount" :key="`specRow-${index}`">
          <div>Количество выпускников по специальности {{ row.name }}
            в {{ regInfo.year }} году
          </div>
          <div style="margin-left: 15px;">
            <v-text-field v-model="regInfo.specialitiesCount[index].count"
                          :min="0" type="number"
                          class="numberTextField"
                          height="20px"
                          dense
                          @change="calculateGenCount"
                          :rules="[v => v > 0]" />
          </div>
        </div>
      </v-form>
    </v-card-text>
    <v-card-actions v-if="showCardActions">
      <v-spacer />
      <v-btn
          outlined
          color="primary"
          @click="close"
      >
        Отмена
      </v-btn>
      <v-btn
          outlined
          color="primary"
          @click="doSave"
          :disabled="disabledSave"
      >
        Сохранить
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { dateMixin, dbMixin, formsMixin, registerMixin } from "@/mixins"

// Я вынес в функцию, чтобы при необходимости можно было сбросить данные.
// в данном случае сброс данных идет за счет перемаунчивания компонента в AccredInfoList
// (т.к к компоненту привязан v-if='dialog', соответственно при закрытии диалога выполняется destroy)
// но вынос инициализации в отдельный метод решил оставить. и метод resetData тоже.
const initializeData = () => {
  return {
    menu: false,
    disabledSave: false,
    mode: 'add',
    role: 'accredInfo',
    accredInfoOrg: [],
    subordinateTypes: [],
    regions: [],
    firstAccredSpecialities: [],
    regInfo: {
      subordinateTypeId: '',
      organizationId: '',
      orgName: '',
      regionSubjectId: '',
      specialitiesCount: [],
      // orgBossFullname: '',
      // orgBossEmail: '',
      // orgBossPhone: '',
      orgBossPosition: '',
      simCenterBossFullname: '',
      simCenterBossEmail: '',
      simCenterBossPhone: '',
      simCenterBossPosition: '',
      year: 2024,
      accred2024: '',
      gStudentCount: 0,
    }
  }
}
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "RespOrg",
  mixins: [dbMixin, dateMixin, registerMixin, formsMixin],
  data () {
    return initializeData()
  },
  async beforeMount () {
    this.getCatalogData('regions')
    await this.getCatalogData('firstAccredSpecialities')
    this.getCatalogData('subordinateTypes')
    this.calculateGenCount()
  },
  computed: {
    organizations () {
      if (this.mode === 'edit') {
        return this.accredInfoOrg.filter((el) => {
          return el.code == this.regInfo.organizationId
        })
      } else {
        return this.accredInfoOrg.filter((el) => {
          return !el.is_used
        })
      }

    }
  },
  watch: {
    'regInfo.specialitiesCount': {
      handler (cur, prev) {
        const curLength = cur.length
        // Условия !cur[curLength - 1].count, добавлено для того,
        // чтобы при редактировании записи не занулялся последний элемент, т.к при редактировании заполняются данные
        // срабатывает вотчер и зануляет
        if (prev.length < curLength && !cur[curLength - 1].count) {
          // Элемент добавлен
          let lastElem = cur[curLength - 1]
          lastElem.count = 0
          lastElem.blockId = curLength
          lastElem.year = this.regInfo.year
        }
      }
    }
  },
  methods: {
    async doSave () {
      this.disabledSave = true
      await this.save()
      this.disabledSave = false
    },
    resetData () {
      const data = initializeData()
      Object.keys(data).forEach(k => this[k] = data[k])
    },
    calculateGenCount () {
      this.regInfo.gStudentCount = this.regInfo.specialitiesCount.reduce((acc, curVal) => {
        return acc + parseInt(curVal.count)
      }, 0)
    },
    filterChosenSpecialities (blockId) {
      // Для выбора доступны только те специальности, которые еще не были выбраны ранее
      return this.firstAccredSpecialities.filter(spec =>
          !this.regInfo.specialitiesCount.some(block =>
              block.code === spec.code && blockId != block.blockId
          )
      )
    },
    emailRule (value) {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!value || !regex.test(value)) {
        return 'Некорретно введена электронная почта';
      } else {
        return true;
      }
    }
  }
}
</script>

<style scoped>
/* Класс для того чтобы приподнять v-text-field чуть выше, чтобы все было в линию*/
.numberTextField {
  position: relative;
  bottom: 8px;
  margin-left: 15px;
  width: 90px;
}

.inlineBlocks {
  display: flex;
}
</style>