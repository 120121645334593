<template>
  <v-radio-group
      :label="label"
      :row="horizontal"
      :value="value"
      @change="updateValue"
  >
    <v-radio
        v-for="(item, index) in radios"
        :key="`radio-${index}`"
        :label="item.label"
        :value="item.value"
    />
    <v-btn v-if="clearable && value !== null" icon @click="updateValue(null)">
      <v-icon>$close</v-icon>
    </v-btn>
  </v-radio-group>
</template>

<script>
export default {
  name: 'BoolSelect',
  props: {
    clearable: {
      type: Boolean,
      default: true
    },
    value: {
      type: Boolean,
      default: null
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    horizontal: {
      type: Boolean,
      default: true
    },
    radios: {
      type: Array,
      default () {
        return [
          {
            label: 'Да',
            value: true
          },
          {
            label: 'Нет',
            value: false
          }
        ]
      }
    }
  },
  methods: {
    updateValue (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style scoped>

</style>
