<template>
  <v-card flat>
    <slot name="cardTitle"></slot>
    <v-card-text>
      <v-form ref="form">
        <v-autocomplete
            v-model="regInfo.organizationId"
            :items="organizations"
            item-value="code"
            item-text="name"
            :rules="[v => !!v]"
            label="Наименование организации, рекомендовавшей эксперта"
            no-data-text="Организаций не найдено"
        ></v-autocomplete>
        <person-card v-if="!loadedInfo" ref="personCard"></person-card>
        <v-text-field v-model="regInfo.position" label="Должность" :rules="[v => !!v]"/>
        <v-text-field v-model="regInfo.degree" label="Ученая степень, звание (при наличии)" :rules="[v => !!v]"/>
        <v-autocomplete
            v-model="regInfo.specialities"
            :items="specialities"
            item-value="code"
            item-text="name"
            multiple
            label="Специальность"
            placeholder="Выберите специальность"
            :rules="[v => v.length > 0]"
            return-object
            no-data-text="Специальности не найдены"
        >
          <template #selection="{ item }">
            <v-chip
                outlined
                color="primary"
                close
                close-icon="mdi-close"
                @click:close="removeSpeciality(item.code)"
            >
              <span>{{ item.name }}</span>
            </v-chip>
          </template>
        </v-autocomplete>
      </v-form>
    </v-card-text>
    <v-card-actions v-if="showCardActions">
      <v-spacer />
      <v-btn
          outlined
          color="primary"
          @click="close"
      >
        Отмена
      </v-btn>
      <v-btn
          outlined
          color="primary"
          @click="save"
      >
        Сохранить
      </v-btn>
    </v-card-actions>
  </v-card>

</template>

<script>
import PersonCard from "@/components/forms/PersonCard"
import { dbMixin, formsMixin, registerMixin } from "@/mixins"

export default {
  name: "BankExpert",
  components: { PersonCard },
  mixins: [dbMixin, registerMixin, formsMixin],
  data () {
    return {
      organizations: [],
      specialities: [],
      role: 'bankExpert',
      regInfo: {
        organizationId: '',
        surname: '',
        firstname: '',
        patronymic: '',
        degree: '',
        position: '',
        specialities: '',
        phone: '',
        email: ''
      }
    }
  },
  beforeMount () {
    this.getCatalogData('organizations')
    this.getCatalogData('specialitiesWithLevel')
  },
  methods: {
    removeSpeciality (specialityId) {
      this.regInfo.specialities = this.regInfo.specialities.filter(el => el.code != specialityId)
    }
  }
}
</script>

<style scoped>

</style>