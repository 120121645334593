<template>
  <v-card flat>
    <v-card-title>
      Список экспертов пакетов ТЗ
    </v-card-title>
    <v-card-text>
      <v-data-table
          :headers="headers"
          :items="expertInfo"
          :items-per-page="10"
          class="stripped"
          no-data-text="Нет данных"

      >
        <template #top>
          <v-toolbar flat dense>
            <data-table-filter
                v-if="filters !== null && Object.keys(enabledFilters).length > 0"
                :value="enabledFilters"
                @input="filtersChange"
            />
            <div style="width: 5px"/>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    @click="downloadExcel">
                  <v-icon>mdi-file-excel</v-icon>
                </v-btn>
              </template>
              Скачать таблицу
            </v-tooltip>
          </v-toolbar>
        </template>
<!--        <template #[`item.specialities`]="{value}">-->
<!--          {{ concatListValues(value) }}-->
<!--        </template>-->
<!--        <template #[`item.disciplines`]="{value}">-->
<!--          {{ concatListValues(value) }}-->
<!--        </template>-->
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>

import { saveAs } from 'file-saver'
import { cacheFilterMixin } from "@/mixins"
import DataTableFilter from "@/components/common/DataTableFilter"

export default {
  name: "PackageExpertPage",
  components: { DataTableFilter },
  mixins: [cacheFilterMixin],
  data () {
    return {
      expertInfo: [],
      filters: {
        surname: {
          value: '',
          label: 'Фамилия',
          type: 'text'
        },
        firstname: {
          value: '',
          label: 'Имя',
          type: 'text'
        },
        patronymic: {
          value: '',
          label: 'Отчество',
          type: 'text'
        }
      },
      headers: [{
        value: 'organization.name',
        text: 'Наименование организации, рекомендовавшей эксперта',
        width: '80px',
      },
        {
          value: 'surname',
          text: 'Фамилия',
          width: '60px'
        },
        {
          value: 'firstname',
          text: 'Имя',
          width: '40px'
        },
        {
          value: 'patronymic',
          text: 'Отчество',
          width: '40px'
        },
        {
          value: 'position',
          text: 'Должность',
          width: '60px'
        },
        {
          value: 'degree',
          text: 'Учёная степень, звание (при наличии)',
          width: '60px'
        },
        {
          value: 'specialities',
          text: 'Специальности',
          width: '60px'
        },
        {
          value: 'disciplines',
          text: 'Разделы (для указанных выше специальностей)',
          width: '60px'
        },
        {
          value: 'phone',
          text: 'Контактный телефон (для мобильной связи)',
          width: '60px'
        },
        {
          value: 'email',
          text: 'Адрес электронной почты (актуальный)',
          width: '60px'
        }]
    }
  },
  computed: {
    enabledFilters () {
      const filters = {}
      let key
      for (key in this.filters) {
        if (this.filters[key].type !== 'hidden') {
          filters[key] = this.filters[key]
        }
      }
      return filters
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.expertInfo = []
      const url = `${process.env.VUE_APP_SERVER_URL}/roles/package_expert?`
      let params = {}
      if (Object.keys(this.enabledFilters).length > 0) {
        Object.keys(this.filters).forEach(key => {params[key] = this.filters[key].value });
      }
      let response = await fetch(url + new URLSearchParams(params));
      if (response.ok) { // если HTTP-статус в диапазоне 200-299
        // получаем тело ответа (см. про этот метод ниже)
        let recs = await response.json();
        recs.forEach((el) => {
          // el.specialities = [{ name: el.spec_name, code: el.speciality_id }]
          // el.disciplines = [{ name: el.dis_name, code: el.discipline_id }]
          el.organization = { name: el.org_name, code: el.organization_id }
          delete el.org_name
          delete el.organization_id
          delete el.spec_name
          delete el.spec_id
          delete el.dis_id
          delete el.dis_name
          this.expertInfo.push(el)
        })
      } else {
        alert("Ошибка HTTP: " + response.status);
      }
    },
    // Использовалась раньше
    // concatListValues (value) {
    //   if (Array.isArray(value)) {
    //     const newList = value.map(x => x.name)
    //     if (newList.length) {
    //       return newList.join(';\n')
    //     }
    //   }
    //   return []
    // },
    filtersChange (data) {
      for (const item in data) {
        // eslint-disable-next-line vue/no-mutating-props
        this.filters[item].value = data[item].value
      }
      this.fetchData()

    },
    async downloadExcel () {
      const url = `${process.env.VUE_APP_SERVER_URL}/excel/package_expert`
      saveAs(url, 'Эксперты пакетов ТЗ.xlsx')
    },
  }

}
</script>

<style scoped>

</style>