<template>
  <v-card flat>
    <v-card-title>
      Список участников вебинаров
    </v-card-title>
    <v-card-text>
      <v-data-table
          :headers="headers"
          :items="webParticipantInfo"
          :items-per-page="10"
          class="stripped"
          no-data-text="Нет данных"
      >
        <template #top>
          <v-toolbar flat dense>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    @click="downloadExcel">
                  <v-icon>mdi-file-excel</v-icon>
                </v-btn>
              </template>
              Скачать таблицу
            </v-tooltip>
          </v-toolbar>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { dbMixin } from "@/mixins"
import { saveAs } from "file-saver"

export default {
  name: "WebinarParticipantPage",
  mixins: [dbMixin],
  data () {
    return {
      headers: [
        {
          value: 'webinar.name',
          text: 'Название вебинара',
          width: '60px'
        },
        {
          value: 'region.name',
          text: 'Субъект (Регион)',
          width: '80px'
        },
        {
          value: 'organization.name',
          text: 'Организация',
          width: '80px'
        },
        {
          value: 'surname',
          text: 'Фамилия',
          width: '60px'
        },
        {
          value: 'firstname',
          text: 'Имя',
          width: '40px'
        },
        {
          value: 'patronymic',
          text: 'Отчество',
          width: '40px'
        },
        {
          value: 'position',
          text: 'Занимаемая должность',
          width: '60px'
        },
        {
          value: 'email',
          text: 'Адрес электронной почты',
          width: '60px'
        },
        {
          value: 'phone',
          text: 'Контактный телефон (для мобильной связи)',
          width: '60px'
        },
      ],

      webParticipantInfo: []
    }
  },
  async beforeMount () {
    const url = `${process.env.VUE_APP_SERVER_URL}/roles/webinar_participant`
    let response = await fetch(url);
    if (response.ok) {
      let items = await response.json();
      items.forEach((el) => {
        el = this.convertKeysToCamelCase(el)
        el.webinar = { uid: el.webinarId, name: el.webinarName }
        el.region = { uid: el.regionId, name: el.regionName }
        el.organization = { id: el.orgId, name: el.orgName }
        this.webParticipantInfo.push(el)
      })
    } else {
      alert("Ошибка HTTP: " + response.status);
    }
  },
  methods: {
    async downloadExcel () {
      const url = `${process.env.VUE_APP_SERVER_URL}/excel/webinar_participant`
      saveAs(url, 'Участники вебинаров.xlsx')
    }
  }

}
</script>

<style scoped>

</style>