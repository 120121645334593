import NotFound from "../components/NotFound"
import AdminPanel from "../components/AdminPanel"

import {
  Accred, BankExpert, Login, PackageExpert, CaseExpert, RespMascList,
  Profile, StdPatient, WebinarParticipant, WebinarList, WorksheetList,
  AccredInfoList, AccredInfoExtended, Welcome, WorksheetChooser, WorksheetResults, WorksheetParticipant, RegTypeList,
  WorksheetAdminRegister
} from "@/views"

import {
  AccredWorksheet,
  AccredStationWorksheet,
  ExpertWorksheet,
  AnReanimWorksheet,
  StudyResWorksheet,
  AdditionalWebinarWorksheet
} from "@/components/worksheets"


const routes = [
  {
    path: '/',
    name: 'welcome',
    component: Welcome,
    meta: {
      layout: 'default'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      layout: 'empty'
    }
  },

  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: {
      layout: 'default'
    }
  },
  {
    path: '/accred',
    name: 'accred',
    component: Accred,
    meta: {
      layout: 'default'
    }
  },

  {
    path: '/respMascList',
    name: 'respMascList',
    component: RespMascList,
    meta: {
      layout: 'default'
    }
  },

  {
    path: '/regTypes',
    name: 'regTypes',
    component: RegTypeList,
    meta: {
      layout: 'default'
    }
  },

  {
    path: '/bankExpert',
    name: 'bankExpert',
    component: BankExpert,
    meta: {
      layout: 'default'
    }
  },
  {
    path: '/packageExpert',
    name: 'packageExpert',
    component: PackageExpert,
    meta: {
      layout: 'default'
    }
  },
  {
    path: '/caseExpert',
    name: 'caseExpert',
    component: CaseExpert,
    meta: {
      layout: 'default'
    }
  },
  {
    path: '/stdPatient',
    name: 'stdPatient',
    component: StdPatient,
    meta: {
      layout: 'default'
    }
  },
  {
    path: '/webinarParticipant',
    name: 'webinarParticipant',
    component: WebinarParticipant,
    meta: {
      layout: 'default'
    }
  },
  {
    path: '/webinarList',
    name: 'webinarList',
    component: WebinarList,
    meta: {
      layout: 'default'
    }
  },
  {
    path: '/accredInfoList',
    name: 'accredInfoList',
    component: AccredInfoList,
    meta: {
      layout: 'default'
    }
  },
  {
    path: '/accredInfoExtended',
    name: 'accredInfoExtended',
    component: AccredInfoExtended,
    meta: {
      layout: 'default'
    }
  },
  {
    path: '/AdminPanel',
    name: 'adminPanel',
    component: AdminPanel
  },
  {
    path: '/ExpertWorksheet',
    name: 'expertWorksheet',
    component: ExpertWorksheet,
    meta: {
      layout: 'worksheet'
    }
  },
  {
    path: '/AccredWorksheet',
    name: 'accredWorksheet',
    component: AccredWorksheet,
    meta: {
      layout: 'worksheet'
    }
  },
  {
    path: '/AnReanimWorksheet',
    name: 'anReanimWorksheet',
    component: AnReanimWorksheet,
    meta: {
      layout: 'worksheet'
    }
  },
  {
    path: '/StudyResWorksheet',
    name: 'studyResWorksheet',
    component: StudyResWorksheet,
    meta: {
      layout: 'worksheet'
    }
  },
  {
    path: '/AccredStationWorksheet',
    name: 'accredStationWorksheet',
    component: AccredStationWorksheet,
    meta: {
      layout: 'worksheet'
    }
  },
  {
    path: '/AdditionalWebinarWorksheet',
    name: 'additionalWebinarWorksheet',
    component: AdditionalWebinarWorksheet,
    meta: {
      layout: 'worksheet'
    }
  },
  {
    path: '/Worksheets',
    name: 'worksheets',
    component: WorksheetList
  },
  {
    path: '/WorksheetParticipant',
    name: 'worksheetParticipant',
    component: WorksheetParticipant
  },
  {
    path: '/WorksheetTest',
    name: 'worksheetTest',
    component: WorksheetChooser
  },
  {
    path: '/WorksheetResults',
    name: 'worksheetResults',
    component: WorksheetResults
  },
  {
    path: '/worksheetAdminRegister',
    name: 'worksheetAdminRegister',
    component: WorksheetAdminRegister
  },
  {
    // Любой роут кроме выше перечисленных. Отображается страница NotFound
    path: '/:pathMatch(.*)',
    component: NotFound
  }
]

export default routes