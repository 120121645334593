import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '@/store'

Vue.use(VueRouter)



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const accessableRoutes = ['expertWorksheet', 'accredWorksheet',
  'anReanimWorksheet', 'studyResWorksheet', 'accredStationWorksheet', 'additionalWebinarWorksheet']
// Защита роутов. В случае если пользователь не авторизован, переводим его на страницу логина
router.beforeEach((to, from, next) => {
  // Если авторизация уже произведена, то на строницу логина не переводим
  if (to.name === 'login') {
    if (store.state.user.username) {
      next({name: 'home'})
    } else {
      next()
    }
    return
  }
  // Проверка на авторизацию пользователя
  //FIXME: Поправить на нормальную проверку авторизации
  if (store.state.user.username || accessableRoutes.includes(to.name)) {
    next()
  } else {
    next({name: 'login'})
  }

})

export default router
