<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <div class="position-relative">
        <span class="display-1 font-weight-bold text-center">
          <span class="typed-text">{{ currentText }}</span>
          <span class="blink-caret">|</span>
        </span>
      </div>
    </v-layout>
  </v-container>
</template>

<style>
.caret {
  position: relative;
}

.blink-caret {
  position: absolute;
  top: 50%;
  transform: translate(-20%, -50%);
  animation: blink-caret-animation 0.9s infinite;
}

@keyframes blink-caret-animation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>

<script>
export default {
  name: 'WelcomeMessage',
  data () {
    return {
      welcomeText: 'Добро пожаловать в систему регистрации пользователей',
      currentText: '',
      currentIndex: 0,
      direction: 1,
      intervalTimer: null,
      calcLeft: ''
    };
  },

  mounted () {
    this.startTyping();
  },

  beforeDestroy () {
    clearInterval(this.intervalTimer);
  },

  methods: {
    startTyping () {
      this.intervalTimer = setInterval(() => {
        if (this.currentIndex < this.welcomeText.length) {
          this.currentText += this.welcomeText[this.currentIndex];
          this.currentIndex++;
        } else {
          this.currentText = '';
          this.currentIndex = 0;
        }
      }, 125);
    }
  },
};
</script>