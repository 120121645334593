<template>
  <v-card flat>
    <v-card-title>
      Обучение членов АК
    </v-card-title>
    <v-card-text>
      <v-data-table
          :headers="headers"
          :items="expertInfo"
          :items-per-page="10"
          class="stripped"
          no-data-text="Нет данных"
      >
        <template #top>
          <v-toolbar flat dense>
            <data-table-filter
                v-if="filters !== null && Object.keys(enabledFilters).length > 0"
                :value="enabledFilters"
                @input="filtersChange"
            />
            <div style="width: 5px" />
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    @click="downloadExcel">
                  <v-icon>mdi-file-excel</v-icon>
                </v-btn>
              </template>
              Скачать таблицу
            </v-tooltip>
          </v-toolbar>
        </template>
        <template #[`item.isBoss`]="{value}">
          {{ value ? 'Да' : 'Нет' }}
        </template>
        <template #[`item.isArchived`]="{value}">
          {{ value ? 'Да' : 'Нет' }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import dbMixin from "@/mixins/dbMixin"
import { saveAs } from 'file-saver'
import DataTableFilter from "@/components/common/DataTableFilter"

export default {
  name: "AccredPage",
  components: { DataTableFilter },
  mixins: [dbMixin],
  data () {
    return {
      filters: {
        surname: {
          value: '',
          label: 'Фамилия',
          type: 'text'
        },
        firstname: {
          value: '',
          label: 'Имя',
          type: 'text'
        },
        patronymic: {
          value: '',
          label: 'Отчество',
          type: 'text'
        },
        isArchived: {
          value: false,
          label: 'Архивный',
          type: 'boolean'
        },
      },
      headers: [{
        value: 'region.name',
        text: 'Субъект (Регион)',
        width: '80px'
      },
        {
          value: 'isBoss',
          text: 'Руководитель организации, структурного подразделения',
          width: '20px'
        },
        {
          value: 'position',
          text: 'Основная врачебная должность по специальности',
          width: '40px'
        },
        {
          value: 'specExperience',
          text: 'Стаж работы по специальности/должности (лет)',
          width: '20px'
        },
        {
          value: 'email',
          text: 'Эл. Почта',
          width: '30px'
        },
        {
          value: 'surname',
          text: 'Фамилия',
          width: '60px'
        },
        {
          value: 'firstname',
          text: 'Имя',
          width: '40px'
        },
        {
          value: 'patronymic',
          text: 'Отчество',
          width: '40px'
        },
        {
          value: 'accredExperience',
          text: 'Стаж работы в аккредитационной комиссии (год)',
          width: '20px'
        },
        {
          value: 'accredStatus.name',
          text: 'Статус в аккредитационной комиссии (АК)/ аккредитационной подкомиссии (АПК)',
          width: '40px'
        },
        {
          value: 'accredType.name',
          text: 'Вид аккредитационной комиссии',
          width: '40px'
        },
        {
          value: 'isArchived',
          text: 'Архивный',
          width: '30px'
        }],
      expertInfo: []
    }
  },
  computed: {
    enabledFilters () {
      const filters = {}
      let key
      for (key in this.filters) {
        if (this.filters[key].type !== 'hidden') {
          filters[key] = this.filters[key]
        }
      }
      return filters
    },
    urlParams () {
      let params = {}
      if (Object.keys(this.enabledFilters).length > 0) {
        Object.keys(this.filters).forEach(key => {
          if (this.filters[key].type === 'text') {
            params[key] = '%' + this.filters[key].value + '%'
          }
          else {
            params[key] = this.filters[key].value
          }

        });
      }
      return params
    }
  },

  async beforeMount () {
    this.fetchData()
  },
  methods: {
    filtersChange (data) {
      for (const item in data) {
        // eslint-disable-next-line vue/no-mutating-props
        this.filters[item].value = data[item].value
      }
      this.fetchData()

    },
    async fetchData () {
      const url = `${process.env.VUE_APP_SERVER_URL}/roles/accred?`

      let response = await fetch(url + new URLSearchParams(this.urlParams));
      if (response.ok) {
        this.expertInfo = []
        let items = await response.json();
        items.forEach((el) => {
          el = this.convertKeysToCamelCase(el)
          el.region = { uid: el.regionId, name: el.regionName }
          el.accredType = { id: el.accredName, name: el.accredTypeName }
          el.accredStatus = { id: el.statusId, name: el.accredStatusName }
          this.expertInfo.push(el)
        })
      } else {
        alert("Ошибка HTTP: " + response.status);
      }
    },
    async downloadExcel () {
      const url = `${process.env.VUE_APP_SERVER_URL}/excel/accred?`
      const request_url =url + new URLSearchParams(this.urlParams)
      saveAs(request_url, 'Обучение членов АК.xlsx')
    }
  }

}
</script>

<style scoped>
</style>