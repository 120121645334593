<template>
  <v-card flat>
    <v-card-text>
      <slot name="cardTitle"></slot>
      <v-form ref="form">
        <v-autocomplete
            v-model="regInfo.regionSubjectId"
            :items="regions"
            item-value="code"
            item-text="name"
            :rules="[v => !!v]"
            label="Субъект (Регион)"
        ></v-autocomplete>
        <v-checkbox v-model="regInfo.isBoss" label="Руководитель организации, структурного подразделения" />
        <v-text-field v-model="regInfo.position" label="Основная врачебная должность по специальности"
                      :rules="[v => !!v]" />
        <v-slider
            v-model="regInfo.specExperience"
            label="Стаж работы по специальности/должности (лет)"
            step="1"
            max="50"
            thumb-label="always"
            ticks
            :rules="[v => v > 0]"
        />
        <person-card v-if="!loadedInfo" ref="personCard"></person-card>
        <v-slider
            v-model="regInfo.accredExperience"
            label="Стаж работы в аккредитационной комиссии (год)"
            step="1"
            max="15"
            thumb-label="always"
            ticks
            :rules="[v => v > 0]"
        />
        <v-select
            v-model="regInfo.statusId"
            :items="accredStatuses"
            item-value="id"
            item-text="name"
            :rules="[v => !!v]"
            label="Статус в аккредитационной комиссии (АК)/ аккредитационной подкомиссии (АПК)"
        />
        <v-select
            v-model="regInfo.accredType"
            :items="accredTypes"
            item-value="id"
            item-text="name"
            multiple
            :rules="[v => v.length > 0]"
            label="Вид аккредитационной комиссии"
        />
      </v-form>
      <v-checkbox v-model="regInfo.isArchived" label="Архивный" />
    </v-card-text>
    <v-card-actions v-if="showCardActions">
      <v-spacer />
      <v-btn
          outlined
          color="primary"
          @click="close"
      >
        Отмена
      </v-btn>
      <v-btn
          outlined
          color="primary"
          @click="save"
      >
        Сохранить
      </v-btn>
    </v-card-actions>
  </v-card>

</template>

<script>
import PersonCard from "@/components/forms/PersonCard"
import { dbMixin, formsMixin, registerMixin } from "@/mixins"

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Accred",
  components: { PersonCard },
  mixins: [dbMixin, registerMixin, formsMixin],
  beforeMount () {
    this.getCatalogData('regions')
    this.getCatalogData('accredTypes')
    this.getCatalogData('accredStatuses')
  },
  data () {
    return {
      role: 'accred',
      accredStatuses: [],
      accredTypes: [],
      regions: [],
      regInfo: {
        isBoss: false,
        regionSubjectId: '',
        specExperience: 0,
        accredExperience: 0,
        statusId: '',
        accredType: [],
        surname: '',
        firstname: '',
        patronymic: '',
        email: '',
        phone: '',
        degree: '',
        position: '',
        isArchived: false
      }
    }
  }
}
</script>

<style scoped>

</style>