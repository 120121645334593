<template>
  <v-card flat>
    <slot name="cardTitle"></slot>
    <v-card-text>
      <v-form ref="form">
        <v-autocomplete
            v-model="regInfo.districtId"
            :items="federalDistricts"
            item-value="id"
            item-text="name"
            :rules="[v => !!v]"
            label="Федеральный округ"
            no-data-text="Нет данных"
        />
        <v-autocomplete
            v-model="regInfo.regionSubjectId"
            :items="regionsByDistrict"
            item-value="code"
            item-text="name"
            :rules="[v => !!v]"
            label="Субъект РФ"
            :disabled="!regInfo.districtId"
            no-data-text="Нет данных"
        ></v-autocomplete>
        <v-select
            v-model="regInfo.subordinateTypeId"
            :items="subordinateTypes"
            item-value="id"
            item-text="name"
            :rules="[v => !!v]"
            label="Подведомственность"
            no-data-text="Нет данных"
        ></v-select>
        <v-autocomplete
            v-if="!isProfileCard"
            v-model="regInfo.organizationId"
            :items="organizations"
            item-value="code"
            item-text="name"
            :rules="[v => !!v]"
            label="Наименование образовательной организации"
            no-data-text="Организаций не найдено"
        />
        <v-text-field
            v-if="isProfileCard"
            v-model="regInfo.orgName"
            label="Наименование образовательной организации"
            readonly />
        <v-text-field
            v-if="!isProfileCard"
            label="Фамилия, имя, отчество ответственного от образовательной организации за проведение ПСА"
            v-model="regInfo.orgBossFullname" :rules="[v => !!v]" />
        <v-text-field
            v-if="!isProfileCard"
            label="Адрес электронной почты (актуальный) ответственного от образовательной организации за проведение ПСА"
            v-model="regInfo.orgBossEmail" type="email" :rules="[emailRule]" />
        <v-text-field
            v-if="!isProfileCard"
            label="Контактный телефон (для мобильной связи) ответственного от образовательной организации за проведение ПСА"
            v-model="regInfo.orgBossPhone" :rules="[v => !!v]" />
        <v-text-field
            v-if="!isProfileCard"
            v-model="regInfo.orgBossPosition"
            label="Занимаемая должность ответственного от образовательной организации
                       за проведение ПСА"
            :rules="[v => !!v]" />
        <v-text-field
            v-model="regInfo.simCenterBossFullname"
            :rules="[v => !!v]"
            label="Фамилия, имя, отчество руководителя аккредитационно-симуляционного центра образовательной
             организации"
        />
        <v-text-field
            v-model="regInfo.simCenterBossEmail"
            type="email"
            :rules="[emailRule]"
            label="Адрес электронной почты руководителя аккредитационно-симуляционного центра образовательной
             организации"
        />
        <v-text-field
            v-model="regInfo.simCenterBossPhone"
            :rules="[v => !!v]"
            label="Контактный телефон (для мобильной связи) руководителя аккредитационно-симуляционного центра
             образовательной организации"
        />

        <v-text-field v-model="regInfo.simCenterBossPosition"
                      :rules="[v => !!v]"
                      label="Должность руководителя аккредитационно-симуляционного центра образовательной
                       организации" />
        <v-select
            v-model="regInfo.accred"
            :rules="[v => v !== '']"
            label="Проведение первичной специализированной аккредитации на базе АЦ Вашей организации в 2025 году"
            :items="[{text: 'Да', value: true}, {text: 'Нет', value: false}]"
        />
        <v-text-field v-model="regInfo.year"
                      label="Год"
                      :min="2025"
                      type="number"
                      readonly
        />
        <v-select
            v-model="chosenEducationLevelId"
            :items="educationLevels"
            item-text="name"
            item-value="id"
            chips
            label="Программа подготовки" />
        <v-select
            v-model="regInfo.specialitiesCount"
            :items="filteredSpecialities"
            item-text="name"
            item-value="code"
            multiple
            chips
            return-object
            label="Специальность, выпускники (кол-во)"
            :rules="[v => v.length > 0]" />
        <div class="inlineBlocks" v-for="(row, index) in regInfo.specialitiesCount" :key="`specRow-${index}`">
          <div>Планируемый выпуск по специальности {{ row.name }} ({{ row.education_level_name }}) в {{ regInfo.year }}
            г.
            (количество выпускников)
          </div>
          <div style="margin-left: 15px;">
            <v-text-field v-model="regInfo.specialitiesCount[index].count"
                          :min="0" type="number"
                          class="numberTextField"
                          height="20px"
                          dense
                          :rules="[v => v > 0]" />
          </div>
        </div>
      </v-form>
    </v-card-text>
    <v-card-actions v-if="showCardActions">
      <v-spacer />
      <v-btn
          outlined
          color="primary"
          @click="close"
      >
        Отмена
      </v-btn>
      <v-btn
          outlined
          color="primary"
          @click="doSave"
          :disabled="disabledSave"
      >
        Сохранить
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { dateMixin, dbMixin, formsMixin, registerMixin } from "@/mixins"

// Я вынес в функцию, чтобы при необходимости можно было сбросить данные.
// в данном случае это не обязательно,
// т.к сброс данных идет за счет перемаунчивания компонента в accredInfoExtendedBaseInfo
// (т.к к компоненту привязан v-if='dialog', соответственно при закрытии диалога выполняется destroy)
// но вынос инициализации в отдельный метод решил оставить. и метод resetData тоже.
const initializeData = () => {
  return {
    menu: false,
    disabledSave: false,
    mode: 'add',
    role: 'accredInfoExtended',
    accredInfoExtendedBaseInfo: [],
    subordinateTypes: [],
    federalDistricts: [],
    regions: [],
    pcaAccredSpecialities: [],
    chosenEducationLevelId: '',
    educationLevels: [],
    regInfo: {
      districtId: '',
      subordinateTypeId: '',
      organizationId: '',
      orgName: '',
      regionSubjectId: '',
      specialitiesCount: [],
      orgBossFullname: '',
      orgBossEmail: '',
      orgBossPhone: '',
      orgBossPosition: '',
      simCenterBossFullname: '',
      simCenterBossEmail: '',
      simCenterBossPhone: '',
      simCenterBossPosition: '',
      year: 2025,
      accred: ''
    }
  }
}
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "AccredInfoExtended",
  mixins: [dbMixin, dateMixin, registerMixin, formsMixin],
  data () {
    return initializeData()
  },
  async beforeMount () {
    await this.getCatalogData('regions')
    this.getCatalogData('federalDistricts')
    this.getCatalogData('accredInfoExtendedBaseInfo')
    await this.getCatalogData('educationLevels')
    this.educationLevels = this.educationLevels.filter((el) => {
      return !([2, 1].includes(el.id))
    })

    await this.getCatalogData('pcaAccredSpecialities')
    this.getCatalogData('subordinateTypes')
    this.fillDefaultSpecCount()
  },
  computed: {
    filteredSpecialities () {
      // Фильтрация по уровню образования
      return this.pcaAccredSpecialities.filter(el => {
        return el.education_level_id === this.chosenEducationLevelId
      })
    },
    isProfileCard () {
      if (this.loadedInfo === null || this.loadedInfo === undefined) {
        return false
      }

      return Object.keys(this.loadedInfo).length > 0
    },
    organizations () {
      if (this.mode === 'edit') {
        return this.accredInfoExtendedBaseInfo.filter((el) => {
          return el.code == this.regInfo.organizationId
        })
      } else {
        return this.accredInfoExtendedBaseInfo.filter((el) => {
          return !el.is_used
        })
      }
    },
    regionsByDistrict () {
      return this.regions.filter((el) => el.district_id === this.regInfo.districtId)
    }
  },
  watch: {
    'regInfo.specialitiesCount': {
      handler (cur, prev) {
        const curLength = cur.length
        // Условия !cur[curLength - 1].count, добавлено для того,
        // чтобы при редактировании записи не занулялся последний элемент, т.к при редактировании заполняются данные
        // срабатывает вотчер и зануляет
        if (prev.length < curLength && !cur[curLength - 1].count) {
          // Элемент добавлен
          let lastElem = cur[curLength - 1]
          lastElem.count = 0
          lastElem.blockId = curLength
          lastElem.year = this.regInfo.year
        }
      }
    }
  },
  methods: {
    async doSave () {
      this.disabledSave = true
      await this.save()
      this.disabledSave = false
    },
    resetData () {
      const data = initializeData()
      Object.keys(data).forEach(k => this[k] = data[k])
    },
    fillDefaultSpecCount () {
      // Заполнение базового набора специальностей, для отметки количества обучающихся
      if (!this.showCardActions && !this.isProfileCard) {
        this.pcaAccredSpecialities.forEach(el => {
          this.regInfo.specialitiesCount.push({
            blockId: this.regInfo.specialitiesCount.length + 1,
            educationLevelId: el.education_level_id,
            name: el.name,
            code: el.code,
            count: 0,
            year: this.regInfo.year
          })
        })
      }
    },
    emailRule (value) {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!value || !regex.test(value)) {
        return 'Некорретно введена электронная почта';
      } else {
        return true;
      }
    }
  }
}
</script>

<style scoped>
/* Класс для того чтобы приподнять v-text-field чуть выше, чтобы все было в линию*/
.numberTextField {
  position: relative;
  bottom: 8px;
  margin-left: 15px;
  width: 90px;
}

.inlineBlocks {
  display: flex;
}
</style>