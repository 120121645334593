const dbMixin = {
  data () {
    return {}
  },
  methods: {
    async getCatalogData (value, filters) {
      // cameCase to snake_case
      const postfix = value.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
      let url;

      if (typeof filters === "object") {
        url = `${process.env.VUE_APP_SERVER_URL}/catalog/${postfix}?` + new URLSearchParams(filters)
      } else {
        url = `${process.env.VUE_APP_SERVER_URL}/catalog/${postfix}`
      }

      let response = await fetch(url);
      if (response.ok) {
        let recs = await response.json();
        recs.forEach((el) => {
          this[value].push(el)
        })
      } else {
        alert("Ошибка HTTP: " + response.status);
      }
    },
    convertKeysToCamelCase (obj) {
      const convertedObj = {};

      for (let key in obj) {
        // eslint-disable-next-line no-prototype-builtins
        let camelKey = key.replace(/_([a-z0-9])/g, function (m, p1) {
          return p1.toUpperCase();
        });
        convertedObj[camelKey] = obj[key];
      }

      return convertedObj;
    },
    boolConverter (value) {
      return value ? 'Да' : 'Нет'
    },
    async errorHandler (response, title) {
      const data = await response.json()
      const errClass = data.error_class.replace('<', '').replace('>', '')
      const errData = this.prettyJson(data.error_data)
      await this.$dialog.error({
        text: `${title}. Отправьте скриншот ошибки разработчикам. <br/>` +
          `Класс ошибки: ${errClass}.<br/> Текст ошибки: ${data.error_text}. <br/> Данные формы: <br/> ${errData}`,
        title: 'Непредвиденная ошибка'
      })
    },
    prettyJson (jsonObj) {
      let jString = JSON.stringify(jsonObj)
      const reg = /"/g
      jString = jString.replace('{', '{<br/>')
        .replace(reg, ' "')
        .replaceAll(',', ',<br/>')
        .replace('}', '<br/>}')

      return jString
    }
  }
}


export default dbMixin;