<template>
  <component :is="layout" />
</template>

<script>

export default {
  name: 'App',
  data: () => {
    return {
    }
  },
  computed: {
    layout () {
      const layoutName = this.$route.meta.layout || 'default'
      return () => import(`./layouts/${layoutName}`)
    }
  }
};
</script>

<style src="./main.css" lang="css" />
