<template>
    <component ref="chosenDialog" v-if="formName && show" :is="formName" :loaded-info="loadedInfo" @close="close" />
</template>

<script>

import * as formCards from "./forms";

export default {
  name: "FormChooser",
  components: { ...formCards },
  props: {
    formName: {
      type: String
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    },
    loadedInfo: {
      type: Object
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>