<template>
  <v-card flat>
    <v-card-title>
      Регистрация руководителей МАСЦ и ответственных лиц за разработку ОС
    </v-card-title>
    <v-card-text>
      <v-dialog
          :value="dialog"
          persistent>
        <resp-masc
            v-if="dialog"
            ref="addEdit"
            @refetch="refetch"
            :show-card-actions="true"
            @close="close" />
      </v-dialog>
      <v-data-table
          :headers="headers"
          :items="respMascInfo"
          :items-per-page="10"
          class="stripped"
          no-data-text="Нет данных"
      >
        <template #top>
          <v-toolbar flat dense>
            <data-table-filter
                v-if="filters !== null && Object.keys(enabledFilters).length > 0"
                :value="enabledFilters"
                @input="filtersChange"
            />
            <div style="width: 5px" />
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    @click="downloadExcel">
                  <v-icon>mdi-file-excel</v-icon>
                </v-btn>
              </template>
              Скачать таблицу
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    @click="refetch"
                >
                  <v-icon>mdi-sync</v-icon>
                </v-btn>
              </template>
              Обновить
            </v-tooltip>
            <v-btn
                color="primary"
                outlined
                @click="clickAddEditRespMasc()"
            >
              Добавить
              <v-icon dark right>
                mdi-plus-thick
              </v-icon>
            </v-btn>
          </v-toolbar>
        </template>
        <template #[`item.respFullname`]="{item}">
          <span v-html="item.respFullname"/>
        </template>
        <template #[`item.respPosition`]="{item}">
          <span v-html="item.respPosition"/>
        </template>
        <template #[`item.respDegree`]="{item}">
          <span v-html="item.respDegree"/>
        </template>
        <template #[`item.respEmail`]="{item}">
          <span v-html="item.respEmail"/>
        </template>
        <template #[`item.respPhone`]="{item}">
          <span v-html="item.respPhone"/>
        </template>
        <template #[`item.speciality`]="{item}">
          <span v-html="item.speciality"/>
        </template>
        <template #[`item.actions`]="{ item }">
          <template>
            <v-btn
                icon
                @click="clickAddEditRespMasc(item)"
                title="Редактировать"
                color="primary"
            >
              <v-icon>
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
          <v-btn
              icon
              @click="confirmDelete(item)"
              title="Удалить"
              color="error"
          >
            <v-icon>
              mdi-delete
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { dateMixin, dbMixin } from "@/mixins"
import { saveAs } from "file-saver"
import { RespMasc } from "@/components/forms"
import DataTableFilter from "@/components/common/DataTableFilter"

export default {
  name: "RespMascPage",
  mixins: [dbMixin, dateMixin],
  components: { DataTableFilter, RespMasc },
  data () {
    return {
      dialog: false,
      filters: {
        orgName: {
          value: '',
          label: 'Название организации',
          type: 'text'
        },
        mascBossFullname: {
          value: '',
          label: 'ФИО руководителя МАСЦ',
          type: 'text'
        }
      },
      headers: [
        {
          value: 'actions',
          text: 'Действия',
          width: '105px',
          sortable: false
        },
        {
          value: 'regionName',
          text: 'Субъект (регион)',
          width: '125px',
        },
        {
          value: 'orgName',
          text: 'Наименование организации, на базе которой создан Методический аккредитационно-симуляционный центр (МАСЦ)\n',
        },
        {
          value: 'mascBossFullname',
          text: 'Фамилия, имя, отчество (полностью)  руководителя МАСЦ',
        },
        {
          value: 'mascBossDegree',
          text: 'Ученая степень, звание (при наличии)',
        },
        {
          value: 'mascBossEmail',
          text: 'Адрес электронной почты (актуальный)',
        },
        {
          value: 'mascBossPhone',
          text: 'Контактный телефон',
        },

        {
          value: 'respFullname',
          text: 'Фамилия, имя, отчество (полностью) ответственного лица за разработку ОС по клиническим рекомендациям Минздрава России для ПСА 2025 года',
        },
        {
          value: 'respPosition',
          text: 'Занимаемая должность',
        },
        {
          value: 'respDegree',
          text: 'Ученая степень, звание (при наличии)',
        },
        {
          value: 'respEmail',
          text: 'Адрес электронной почты (актуальный)',
        },
        {
          value: 'respPhone',
          text: 'Контактный телефон',
        },
        {
          value: 'speciality',
          text: 'Специальности',
          width: '125px'
        }
      ],
      respMascInfo: [],
    }
  },
  computed: {
    enabledFilters () {
      const filters = {}
      let key
      for (key in this.filters) {
        if (this.filters[key].type !== 'hidden') {
          filters[key] = this.filters[key]
        }
      }
      return filters
    },
    urlParams () {
      let params = {}
      if (Object.keys(this.enabledFilters).length > 0) {
        Object.keys(this.filters).forEach(key => {
          if (this.filters[key].type === 'text') {
            params[key] = '%' + this.filters[key].value + '%'
          }
          else {
            params[key] = this.filters[key].value
          }

        });
      }
      return params
    }
  },
  async beforeMount () {
    await this.refetch()
  },
  methods: {
    filtersChange (data) {
      for (const item in data) {
        // eslint-disable-next-line vue/no-mutating-props
        this.filters[item].value = data[item].value
      }
      this.refetch()

    },
    async downloadExcel () {
      const url = `${process.env.VUE_APP_SERVER_URL}/excel/resp_masc`
      saveAs(url, 'Регистрация руководителей МАСЦ и ответственных лиц за разработку ОС.xlsx')
    },
    clickAddEditRespMasc (item) {
      this.openAddEditDialog(item)
    },
    async confirmDelete (item) {
      if (await this.$dialog.confirm({
        text: `Вы действительно хотите удалить запись?`
      })) {
        this.delete(item)
      }
    },
    async delete (item) {
      const url = `${process.env.VUE_APP_SERVER_URL}/mutation/resp_masc`
      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: item.organizationId, mascBossId: item.mascBossId, respId: item.respId }),
      });
      if (response.status == 200) {
        this.$dialog.notify.success("Запись удалена", { dismissible: false, timeout: 1000 })
        this.refetch()
      } else {
        await this.errorHandler(response, 'Ошибка при удалении')
      }
    },
    async refetch () {
      const url = `${process.env.VUE_APP_SERVER_URL}/catalog/resp_masc?`

      const info = []
      let response = await fetch(url + new URLSearchParams(this.urlParams));
      if (response.ok) { // если HTTP-статус в диапазоне 200-299
        // получаем тело ответа (см. про этот метод ниже)
        let items = await response.json();
        items.forEach((el) => {
          el = this.convertKeysToCamelCase(el)
          info.push(el)
        })
        this.respMascInfo = info.slice()
      } else {
        alert("Ошибка HTTP: " + response.status);
      }
    },
    openAddEditDialog (item) {
      this.dialog = true
      if (item) {
        this.$nextTick(() => {
          this.loadInfoToRefCard(item)
        })
      }
    },
    async loadInfoToRefCard (item) {
      for (let key in this.$refs.addEdit.regInfo) {
        this.$refs.addEdit.regInfo[key] = item[key]
      }

      this.$nextTick(() => {
        this.$refs.addEdit.regInfo.mode = 'edit'
      })
    },
    close () {
      this.dialog = false
    }
  }

}
</script>

<style scoped>
.v-data-table .v-toolbar__content > .v-btn {
  margin-right: 10px;
}
</style>