<template>
  <v-card flat>
    <v-card-title>
      Список стандартизированных пациентов
    </v-card-title>
    <v-card-text>
      <v-data-table
          :headers="headers"
          :items="expertInfo"
          :items-per-page="10"
          class="stripped"
          no-data-text="Нет данных"
      >
        <template #top>
          <v-toolbar flat dense>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    @click="downloadExcel">
                  <v-icon>mdi-file-excel</v-icon>
                </v-btn>
              </template>
              Скачать таблицу
            </v-tooltip>
          </v-toolbar>
        </template>
        <template #[`item.studied${year}`]="{value}" v-for="year in years">
          {{ boolConverter(value) }}
        </template>
        <template #[`item.patient${year}`]="{value}" v-for="year in years">
          {{ boolConverter(value) }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { dbMixin, dateMixin } from "@/mixins"
import { saveAs } from "file-saver"

export default {
  name: "StdpatientPage",
  mixins: [dbMixin, dateMixin],
  data () {
    return {
      headers: [
        {
          value: 'region.name',
          text: 'Субъект (Регион)',
          width: '80px'
        },
        {
          value: 'subordinateType.name',
          text: 'Подведомственность организации',
          width: '35px'
        },
        {
          value: 'organization.name',
          text: 'Организация',
          width: '80px'
        },
        {
          value: 'surname',
          text: 'Фамилия',
          width: '60px'
        },
        {
          value: 'firstname',
          text: 'Имя',
          width: '40px'
        },
        {
          value: 'lastname',
          text: 'Отчество',
          width: '40px'
        },
        {
          value: 'position',
          text: 'Занимаемая должность в организации по основному месту работы\n',
          width: '60px'
        },
        {
          value: 'email',
          text: 'Адрес электронной почты (актуальный)',
          width: '60px'
        },
        {
          value: 'studied2019',
          text: 'Проходил ли обучение в 2019 г.',
          width: '30px'
        },
        {
          value: 'studied2020',
          text: 'Проходил ли обучение в 2020 г.',
          width: '30px'
        },
        {
          value: 'studied2021',
          text: 'Проходил ли обучение в 2021 г.',
          width: '30px'
        },
        {
          value: 'studied2022',
          text: 'Проходил ли обучение в 2022 г.',
          width: '30px'
        },
        {
          value: 'studied2023',
          text: 'Проходил ли обучение в 2023 г.',
          width: '30px'
        },
        {
          value: 'patient2019',
          text: 'Участие в качестве СП в аккредитации 2019 г.',
          width: '30px'
        },
        {
          value: 'patient2020',
          text: 'Участие в качестве СП в аккредитации 2020 г.',
          width: '30px'
        },
        {
          value: 'patient2021',
          text: 'Участие в качестве СП в аккредитации 2021 г.',
          width: '30px'
        },
        {
          value: 'patient2022',
          text: 'Участие в качестве СП в аккредитации 2022 г.',
          width: '30px'
        },
        {
          value: 'patient2023',
          text: 'Участие в качестве СП в аккредитации 2023 г.',
          width: '30px'
        }
      ],
      years: [2019, 2020, 2021, 2022, 2023],
      expertInfo: []
    }
  },
  async beforeMount () {
    const url = `${process.env.VUE_APP_SERVER_URL}/roles/stdpatient`
    let response = await fetch(url);
    if (response.ok) { // если HTTP-статус в диапазоне 200-299
      // получаем тело ответа (см. про этот метод ниже)
      let items = await response.json();
      items.forEach((el) => {
        el = this.convertKeysToCamelCase(el)
        el.region = { uid: el.regionId, name: el.regionName }
        el.organization = { id: el.orgId, name: el.orgName }
        el.subordinateType = { id: el.subId, name: el.subName }
        this.expertInfo.push(el)
      })
    } else {
      alert("Ошибка HTTP: " + response.status);
    }
  },
  methods: {
    async downloadExcel () {
      const url = `${process.env.VUE_APP_SERVER_URL}/excel/stdpatient`
      saveAs(url, 'Стандартизированные пациенты.xlsx')
    }
  }

}
</script>

<style scoped>

</style>