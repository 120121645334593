<template>
  <v-card>
    <v-card-title>
      Страница не найдена 404
    </v-card-title>
    <v-card-text>
      Список доступных страниц:
      <v-list>
        <v-list-item>
          <router-link :to="{name:'home'}">
            Страница регистрации
          </router-link>
        </v-list-item>
        <v-list-item>
          <router-link :to="{name: 'authorization'}">
            Страница авторизации
          </router-link>
        </v-list-item>
        <v-list-item>
          <router-link :to="{name:'adminPanel'}">
            Страница администрирования
          </router-link>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "NotFound"
}
</script>

<style scoped>

</style>