<template>
  <v-card flat>
    <v-card-title>Запись на анкетирование</v-card-title>
    <v-card-text>
      <v-autocomplete
          v-model="info.webinarId"
          label="Выберите вебинар"
          :items="webinars"
          item-value="id"
          item-text="name" />
      <v-select
          v-if="info.webinarId"
          v-model="info.chosenWebParticipants"
          label="Укажите участников вебинара (по умолчанию все)"
          chips
          deletable-chips
          clearable
          :items="webinarParticipants"
          item-value="person_id"
          :item-text="item => item.surname + ' ' + item.firstname + ' ' + item.patronymic + ' (' + item.email + ')' "
          return-object
          multiple
      />
      <v-autocomplete
          v-if="info.chosenWebParticipants.length > 0"
          v-model="info.worksheetId"
          item-value="id"
          :item-text="item => `Мероприятие ${item.id}. ` + item.name + ' [' + date(item.date) + ']'"
          label="Выберите анкетирование"
          :items="worksheets"
      />
    </v-card-text>
    <v-card-actions>
      <v-btn @click="save">
        Записать
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from "vuex"
import { dbMixin, dateMixin } from "@/mixins"

export default {
  name: "WorksheetAdminRegister",
  mixins: [dbMixin, dateMixin],
  data () {
    return {
      webinarId: '',
      webinars: [],
      info: {
        webinarId: '',
        chosenWebParticipants: [],
        worksheetId: ''
      },
      webinarParticipants: [],
      worksheets: [],

    }
  },
  computed: {
    ...mapState({ userId: (state) => state.user.userId }),
  },
  async beforeMount () {
    await this.getCatalogData('webinars', { isActual: true })
    await this.getCatalogData('worksheets', { isActual: true })
  },
  watch: {
    async 'info.webinarId' (value) {
      this.webinarParticipants = []
      this.chosenWebParticipants = []
      const url = `${process.env.VUE_APP_SERVER_URL}/roles/webinar_participant_full_list?webinar_id=${value}`
      let response = await fetch(url);

      if (response.ok) {
        this.webinarParticipants = await response.json();
        this.info.chosenWebParticipants = JSON.parse(JSON.stringify(this.webinarParticipants))
      } else {
        alert("Ошибка HTTP: " + response.status);
      }
    }
  },
  methods: {
    async save () {
      const url = `${process.env.VUE_APP_SERVER_URL}/worksheet/admin_register`
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...this.info}),
      });
      if (response.status === 200) {
        await this.$dialog.notify.success('Запись на анкетирование выполнена', { timeout: 1500 })
      } else {
        await this.errorHandler(response, 'Ошибка при сохранении анкеты')
      }
    }
  }
}
</script>