<template>
  <v-card flat>
    <v-card-title>
      Список мероприятий анкетирования
    </v-card-title>
    <v-card-text>
      <worksheet ref="addEdit" @refetch="refetch" :worksheetTypes="worksheetTypes" />
      <v-data-table
          :headers="headers"
          :items="worksheetInfo"
          :items-per-page="10"
          class="stripped"
          no-data-text="Нет данных"
      >
        <template #top>
          <v-toolbar flat dense>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    @click="downloadExcel">
                  <v-icon>mdi-file-excel</v-icon>
                </v-btn>
              </template>
              Скачать таблицу
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    @click="refetch"
                >
                  <v-icon>mdi-sync</v-icon>
                </v-btn>
              </template>
              Обновить
            </v-tooltip>
            <v-btn
                color="primary"
                outlined
                @click="clickAddEditWorksheet()"
            >
              Добавить
              <v-icon dark right>
                mdi-plus-thick
              </v-icon>
            </v-btn>
          </v-toolbar>
        </template>
        <template #[`item.worksheetType`]="{item}">
          {{ item.name }}
        </template>
        <template #[`item.isActual`]="{value}">
          {{ boolConverter(value) }}
        </template>
        <template #[`item.date`]="{value}">
          {{ date(value) }}
        </template>
        <template #[`item.pRatio`]="{item}">
          {{ item.pCount }}
        </template>
        <template #[`item.actions`]="{ item }">
          <template>
            <v-btn
                icon
                @click="clickAddEditWorksheet(item)"
                title="Редактировать"
                color="primary"
            >
              <v-icon>
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
          <v-btn
              icon
              @click="confirmDelete(item)"
              title="Удалить"
              color="error"
          >
            <v-icon>
              mdi-delete
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { dateMixin, dbMixin } from "@/mixins"
import { saveAs } from "file-saver"
import Worksheet from "@/components/forms/Worksheet"

export default {
  name: "WorksheetPage",
  mixins: [dbMixin, dateMixin],
  components: { Worksheet },
  data () {
    return {
      worksheetTypes: [],
      headers: [
        {
          value: 'id',
          text: 'Идентификатор'
        },
        {
          value: 'worksheetType',
          text: 'Тип мероприятия'
        },
        {
          value: 'date',
          text: 'Дата мероприятия',
        },
        {
          value: 'isActual',
          text: 'Актуальное',
        },
        {
          value: 'pRatio',
          text: 'Количество записанных участников',
        },
        {
          value: 'actions',
          text: 'Действия',
          width: '150px'
        }
      ],
      worksheetInfo: [],
    }
  },
  async beforeMount () {
    await this.getCatalogData('worksheetTypes')
    await this.refetch()
  },
  methods: {
    async downloadExcel () {
      const url = `${process.env.VUE_APP_SERVER_URL}/excel/worksheets`
      saveAs(url, 'Мероприятия анкетирования.xlsx')
    },
    clickAddEditWorksheet (item) {
      this.$refs.addEdit.openAddEditDialog(item)
    },
    async confirmDelete (item) {
      if (await this.$dialog.confirm({
        text: `Вы действительно хотите удалить вебинар '${item.name}'?`
      })) {
        this.delete(item)
      }
    },
    send_delete_request (item, forceDelete = false) {
      const url = `${process.env.VUE_APP_SERVER_URL}/mutation/worksheet`
      return fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: item.id, forceDelete }),
      });
    },
    async delete (item) {
      let response = await this.send_delete_request(item)
      let errorStatus = false
      if (response.status == 200) {
        const data = await response.json()
        if (data?.warning_text) {
          const confirm = await this.$dialog.confirm({
            title: 'Предупреждение',
            text: `${data.warning_text} Все равно удалить?`,
            actions: {
              false: 'Отмена',
              true: {
                text: 'Да',
                color: 'primary'
              }
            }
          })
          if (confirm) {
            response = await this.send_delete_request(item, true)
            if (response.status === 500) {
              errorStatus = true
            }
          } else return
        }
      } else {
        errorStatus = true
      }
      if (!errorStatus) {
        this.$dialog.notify.success("Вебинар удален", { dismissible: false, timeout: 1000 })
        this.refetch()
      } else {
        await this.errorHandler(response, 'Ошибка обработки вебинара')
      }
    },
    async refetch () {
      const url = `${process.env.VUE_APP_SERVER_URL}/catalog/worksheets`
      const info = []
      let response = await fetch(url);
      if (response.ok) {
        let items = await response.json();
        items.forEach((el) => {
          el = this.convertKeysToCamelCase(el)
          info.push(el)
        })
        this.worksheetInfo = info.slice()
      } else {
        alert("Ошибка HTTP: " + response.status);
      }
    }
  }

}
</script>

<style scoped>
.v-data-table .v-toolbar__content > .v-btn {
  margin-right: 10px;
}
</style>