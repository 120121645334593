<template>
  <v-card flat>
    <v-card-title>
      Список вебинаров
    </v-card-title>
    <v-card-text>
      <webinar ref="addEdit" @refetch="refetch" />
      <v-data-table
          :headers="headers"
          :items="webinarInfo"
          :items-per-page="10"
          class="stripped"
          no-data-text="Нет данных"
      >
        <template #top>
          <v-toolbar flat dense>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    @click="downloadExcel">
                  <v-icon>mdi-file-excel</v-icon>
                </v-btn>
              </template>
              Скачать таблицу
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    @click="refetch"
                >
                  <v-icon>mdi-sync</v-icon>
                </v-btn>
              </template>
              Обновить
            </v-tooltip>
            <v-btn
                color="primary"
                outlined
                @click="clickAddEditWebinar()"
            >
              Добавить
              <v-icon dark right>
                mdi-plus-thick
              </v-icon>
            </v-btn>
          </v-toolbar>
        </template>
        <template #[`item.isActual`]="{value}">
          {{ boolConverter(value) }}
        </template>
        <template #[`item.date`]="{value}">
          {{ date(value) }}
        </template>
        <template #[`item.actions`]="{ item }">
          <template>
            <v-btn
                icon
                @click="clickAddEditWebinar(item)"
                title="Редактировать"
                color="primary"
            >
              <v-icon>
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
          <v-btn
              icon
              @click="confirmDelete(item)"
              title="Удалить"
              color="error"
          >
            <v-icon>
              mdi-delete
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { dateMixin, dbMixin } from "@/mixins"
import { saveAs } from "file-saver"
import Webinar from "@/components/forms/Webinar"

export default {
  name: "WebinarPage",
  mixins: [dbMixin, dateMixin],
  components: { Webinar },
  data () {
    return {
      headers: [
        {
          value: 'name',
          text: 'Название вебинара'
        },
        {
          value: 'date',
          text: 'Дата вебинара',
        },
        {
          value: 'isActual',
          text: 'Актуальный',
        },
        {
          value: 'actions',
          text: 'Действия',
          width: '150px'
        }
      ],
      webinarInfo: [],
    }
  },
  beforeMount () {
    this.refetch()
  },
  methods: {
    async downloadExcel () {
      const url = `${process.env.VUE_APP_SERVER_URL}/excel/webinars`
      saveAs(url, 'Вебинар.xlsx')
    },
    clickAddEditWebinar (item) {
      this.$refs.addEdit.openAddEditDialog(item)
    },
    async confirmDelete (item) {
      if (await this.$dialog.confirm({
        text: `Вы действительно хотите удалить вебинар '${item.name}'?`
      })) {
        this.delete(item)
      }
    },
    async delete (item) {
      const url = `${process.env.VUE_APP_SERVER_URL}/mutation/webinar`
      const response = await fetch(url, {
        method: "DELETE", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: item.id }),
      });
      if (response.status == 200) {
        this.$dialog.notify.success("Вебинар удален", { dismissible: false, timeout: 1000 })
        this.refetch()
      } else {
        await this.errorHandler(response, 'Ошибка обработки вебинара')
      }
    },
    async refetch () {
      const url = `${process.env.VUE_APP_SERVER_URL}/catalog/webinars`
      const info = []
      let response = await fetch(url);
      if (response.ok) { // если HTTP-статус в диапазоне 200-299
        // получаем тело ответа (см. про этот метод ниже)
        let items = await response.json();
        items.forEach((el) => {
          el = this.convertKeysToCamelCase(el)
          info.push(el)
        })
        this.webinarInfo = info.slice()
      } else {
        alert("Ошибка HTTP: " + response.status);
      }
    }
  }

}
</script>

<style scoped>
.v-data-table .v-toolbar__content > .v-btn {
  margin-right: 10px;
}
</style>