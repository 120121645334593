<template>
  <v-card flat>
    <slot name="cardTitle"></slot>
    <v-card-text>
      <v-form ref="form">
        <v-autocomplete
            v-model="regInfo.organizationId"
            :items="organizations"
            item-value="code"
            item-text="name"
            :rules="[v => !!v]"
            label="Наименование организации, рекомендовавшей эксперта"
            no-data-text="Организаций не найдено"
        ></v-autocomplete>
        <person-card v-if="!loadedInfo" ref="personCard"></person-card>
        <v-text-field v-model="regInfo.position" label="Должность" :rules="[v => !!v]" />
        <v-text-field v-model="regInfo.degree" label="Ученая степень, звание (при наличии)" :rules="[v => !!v]" />
        <v-btn
            :disabled="allSpecialitiesChosen"
            color="primary"
            :style="specBtnStyle"
            outlined
            @click="addSpecBlock"
        >
          Добавить специальность
          <v-icon dark right>
            mdi-plus-thick
          </v-icon>
        </v-btn>
        <span style="color:#2196F3" v-if="allSpecialitiesChosen">
          - Все доступные специальности выбраны
        </span>
        <div style="margin-top: 15px;">
          <v-row v-for="(block, index) in regInfo.specialities" :key="index">
            <v-col>
              <v-autocomplete
                  v-model="block.code"
                  :items="filterChosenSpecialities(block.blockId)"
                  item-value="code"
                  item-text="name"
                  label="Специальность"
                  placeholder="Выберите специальность"
                  :rules="[v => !!v]"
                  prepend-icon="mdi-close"
                  @click:prepend="removeSpecBlock(index)"
                  @change="clearCurrentSpecDisciplines(block)"
                  no-data-text="Специальности не найдены"
              />
            </v-col>
            <v-col>
              <v-autocomplete
                  v-model="block.disciplines"
                  :disabled="!block.code"
                  :items="filterDisciplineBySpecId(block.code)"
                  item-value="code"
                  item-text="name"
                  label="Дисциплина"
                  placeholder="Выберите дисциплину(-ы)"
                  multiple
                  :rules="[(v) => v.length > 0]"
                  no-data-text="Дисциплины не найдены"
                  :menu-props="{top: true}"
                  return-object
              >
                <template #selection="{ item }">
                  <v-chip
                      outlined
                      color="primary"
                      close
                      close-icon="mdi-close"
                      @click:close="removeDiscipline(block, item.code)"
                  >
                    <span>{{ item.name }}</span>
                  </v-chip>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </div>
      </v-form>
    </v-card-text>
    <v-card-actions v-if="showCardActions">
      <v-spacer />
      <v-btn
          outlined
          color="primary"
          @click="close"
      >
        Отмена
      </v-btn>
      <v-btn
          outlined
          color="primary"
          @click="saveExpert"
      >
        Сохранить
      </v-btn>
    </v-card-actions>
  </v-card>

</template>

<script>
import PersonCard from "@/components/forms/PersonCard"
import { dbMixin, formsMixin, registerMixin } from "@/mixins"

export default {
  name: "PackageExpert",
  components: { PersonCard },
  mixins: [dbMixin, registerMixin, formsMixin],
  data () {
    return {
      specialitiesWithLevel: [],
      disciplines: [],
      organizations: [],
      role: 'packageExpert',
      regInfo: {
        organizationId: '',
        specialities: [],
        surname: '',
        firstname: '',
        patronymic: '',
        position: '',
        degree: '',
        phone: '',
        email: ''
      },
      specBtnStyle: {}
    }
  },
  beforeMount () {
    this.getCatalogData('organizations')
    this.getCatalogData('specialitiesWithLevel')
    this.getCatalogData('disciplines')
  },
  computed: {
    allSpecialitiesChosen () {
      return this.regInfo.specialities.length === this.specialitiesWithLevel.length
    }
  },
  methods: {
    async saveExpert () {
      if (!this.regInfo.specialities.length) {
        if (this.$refs.form.validate()) {
          await this.$dialog.error({
            text: 'Укажите хотя бы одну специальность',
            title: 'Отсутствие специальностей',
            width: 400
          })
        } else {
          this.specBtnStyle = { borderColor: 'red' }
        }
      } else {
        await this.save()
      }
    },
    addSpecBlock () {
      // Добавление блока специальность - дисциплины
      this.regInfo.specialities.push({
        blockId: this.regInfo.specialities.length + 1,
        code: '',
        disciplines: []
      })
    },
    removeSpecBlock (index) {
      this.regInfo.specialities.splice(index, 1)
    },
    filterChosenSpecialities (blockId) {
      // Для выбора доступны только те специальности, которые еще не были выбраны ранее
      return this.specialitiesWithLevel.filter(spec =>
          !this.regInfo.specialities.some(block =>
              block.code === spec.code && blockId != block.blockId
          )
      )
    },
    filterDisciplineBySpecId (specId) {
      return this.disciplines.filter((el) => el.speciality_id === specId)
    },
    clearCurrentSpecDisciplines (speciality) {
      //  При смене специальности необходимо очистить список дисциплин от предыдущей выбранной спецаильности
      speciality.disciplines = []
    },
    removeDiscipline (specBlock, disciplineId) {
      specBlock.disciplines = specBlock.disciplines.filter(el => el.code != disciplineId)
    }
  }
}
</script>

<style>
</style>