const formsMixin = {
  data () {
  },
  props: {
    show: {
      type: Boolean
    },
    showCardActions: {},
    loadedInfo: {
      type: Object
    }
  },
  watch: {
    loadedInfo: {
      deep: true,
      handler (data) {
        this.regInfo = { ...data }
      }
    }
  },
  beforeMount () {
    if (this.loadedInfo) {
      Object.keys(this.loadedInfo).forEach((key) => {
        // eslint-disable-next-line no-prototype-builtins
        if (this.regInfo.hasOwnProperty(key)) {
          this.regInfo[key] = this.loadedInfo[key];
        }
      })
    }
  },
  methods: {
    emailRule (value) {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!value || !regex.test(value)) {
        return 'Некорретно введена электронная почта';
      } else {
        return true;
      }
    }
  }
}

export default formsMixin;