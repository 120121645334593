const dateMixin = {
  methods: {
    dateTime (value) {
      if (value) {
        return new Date(value).toLocaleString('ru-RU', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: '2-digit'
        })
      }
    },
    textDateTime (value) {
      if (value) {
        return new Date(value).toLocaleString('ru-RU', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: '2-digit'
        })
      }
    },
    date (value) {
      if (value) {
        return new Date(value).toLocaleDateString()
      }
    },
    pickerDate (value) {
      if (value) {
        return new Date(value).toISOString().split('T')[0]
      }
    }
  }
}

export default dateMixin
