<template>
  <v-card flat>
    <v-card-title>
      Первичная специализированная аккредитация_сведения
    </v-card-title>
    <v-card-text>
      <v-dialog
          :value="dialog"
          persistent>
        <org-info
            v-if="dialog"
            ref="addEdit"
            @refetch="refetch"
            :show-card-actions="true"
            @close="close" />
      </v-dialog>
      <v-data-table
          :headers="headers"
          :items="orgInfo"
          :items-per-page="10"
          class="stripped"
          no-data-text="Нет данных"
      >
        <template #top>
          <v-toolbar flat dense>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    @click="downloadExcel">
                  <v-icon>mdi-file-excel</v-icon>
                </v-btn>
              </template>
              Скачать таблицу
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    @click="refetch"
                >
                  <v-icon>mdi-sync</v-icon>
                </v-btn>
              </template>
              Обновить
            </v-tooltip>
            <v-btn
                color="primary"
                outlined
                @click="clickAddEditOrgInfo()"
            >
              Добавить
              <v-icon dark right>
                mdi-plus-thick
              </v-icon>
            </v-btn>
          </v-toolbar>
        </template>
        <template #[`header.accred`]="{header}">
          <span v-html="header.text"/>
        </template>
        <template #[`item.accred`]="{value}">
          {{ boolConverter(value) }}
        </template>
        <template #[`item.specialityName`]="{value}">
          <span v-html="value" />
        </template>
        <template #[`item.educationLevelName`]="{value}">
          <span v-html="value" />
        </template>
        <template #[`item.specStudentCount`]="{value}">
          <span v-html="value" />
        </template>
        <template #[`item.actions`]="{ item }">
          <template>
            <v-btn
                icon
                @click="clickAddEditOrgInfo(item)"
                title="Редактировать"
                color="primary"
            >
              <v-icon>
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
          <v-btn
              icon
              @click="confirmDelete(item)"
              title="Удалить"
              color="error"
          >
            <v-icon>
              mdi-delete
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { dateMixin, dbMixin } from "@/mixins"
import { saveAs } from "file-saver"
import OrgInfo from "@/components/forms/AccredInfoExtended"

export default {
  name: "OrgInfoPage",
  mixins: [dbMixin, dateMixin],
  components: { OrgInfo },
  data () {
    return {
      dialog: false,
      headers: [
        {
          value: 'actions',
          text: 'Действия',
          width: '105px',
          sortable: false
        },
        {
          value: 'districtName',
          text: 'Федеральный округ',
          width: '100px',
        },
        {
          value: 'regionName',
          text: 'Субъект РФ',
          width: '125px',
        },
        {
          value: 'subName',
          text: 'Подведомственность',
        },
        {
          value: 'orgName',
          text: 'Наименование образовательной организации',
        },
        {
          value: 'specialityName',
          text: 'Специальность',
        },
        {
          value: 'educationLevelName',
          text: 'Программа подготовки  (ординатура, профпереподготовка, немедики, магистратура)'
        },
        {
          value: 'specStudentCount',
          text: 'Планируемый выпуск в 2025 г. (количество выпускников)'
        },
        {
          value: 'accred',
          text: 'Является площадкой для аккредитации в 2025 г <br/> ' +
              '(да / нет)'
        },
        {
          value: 'simCenterBossFullname',
          text: 'Фамилия, имя, отчество руководителя аккредитационно-симуляционного центра образовательной организации',
        },
        {
          value: 'simCenterBossPosition',
          text: 'Должность руководителя аккредитационно-симуляционного центра образовательной организации',
        },
        {
          value: 'simCenterBossEmail',
          text: 'Адрес электронной почты руководителя аккредитационно-симуляционного центра образовательной организации',
        },
        {
          value: 'simCenterBossPhone',
          text: 'Контактный телефон (для мобильной связи) руководителя аккредитационно-симуляционного центра' +
              ' образовательной организации',
        },
        {
          value: 'orgBossFullname',
          text: 'Фамилия, имя, отчество ответственного от образовательной организации за проведение ПСА',
        },
        {
          value: 'orgBossPosition',
          text: 'Должность ответственного от образовательной организации за проведение ПСА',
        },
        {
          value: 'orgBossEmail',
          text: 'Адрес электронной почты ответственного от образовательной организации за проведение ПСА',
        },
        {
          value: 'orgBossPhone',
          text: 'Контактный телефон (для мобильной связи) ответственного от образовательной организации за проведение ПСА',
        }
      ],
      orgInfo: [],
    }
  },
  async beforeMount () {
    await this.refetch()
  },
  methods: {
    getEducationLevelValue (item, value) {
      const specArr = item.specialityName.split('<br/>')
      let edLvlArr = value.split('<br/>')
      for (let i = 0; i < specArr.length; i++) {
        if (specArr[i].length > 24) {
          edLvlArr[i] += '<br/><br/><br/>'
        } else if (specArr[i]) {
          edLvlArr[i] += '<br/><br/>'
        }
      }
      return edLvlArr.join("")
    },
    getStudentNumberValue (item, value) {
      const edLvlArr = item.educationLevelName.split('<br/>')
      let specStudCount = value.split('<br/>')
      for (let i = 0; i < edLvlArr.length; i++) {
        if (edLvlArr[i].length > 24) {
          specStudCount[i] += '<br/><br/><br/>'
        } else if (edLvlArr[i]) {
          specStudCount[i] += '<br/><br/>'
        }
      }
      return specStudCount.join("")
    },
    hasRussianLetter (str) {
      const russianLetters = /[а-яА-Я]/
      return russianLetters.test(str)
    },
    async downloadExcel () {
      const url = `${process.env.VUE_APP_SERVER_URL}/excel/accred_info_extended`
      saveAs(url, 'Первичная аккредитация_сведения_2.xlsx')
    },
    clickAddEditOrgInfo (item) {
      this.openAddEditDialog(item)
    },
    async confirmDelete (item) {
      if (await this.$dialog.confirm({
        text: `Вы действительно хотите удалить запись?`
      })) {
        this.delete(item)
      }
    },
    async delete (item) {
      const url = `${process.env.VUE_APP_SERVER_URL}/mutation/accred_info_extended`
      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: item.organizationId }),
      });
      if (response.status == 200) {
        this.$dialog.notify.success("Запись удалена", { dismissible: false, timeout: 1000 })
        this.refetch()
      } else {
        await this.errorHandler(response, 'Ошибка при удалении')
      }
    },
    async refetch () {
      const url = `${process.env.VUE_APP_SERVER_URL}/catalog/accred_info_extended`
      const info = []
      let response = await fetch(url);
      if (response.ok) { // если HTTP-статус в диапазоне 200-299
        // получаем тело ответа (см. про этот метод ниже)
        let items = await response.json();
        items.forEach((el) => {
          el = this.convertKeysToCamelCase(el)
          info.push(el)
        })
        this.orgInfo = info.slice()
      } else {
        alert("Ошибка HTTP: " + response.status);
      }
    },
    openAddEditDialog (item) {
      this.dialog = true
      if (item) {
        this.$nextTick(() => {
          this.loadInfoToRefCard(item)
        })
      }
    },
    async loadInfoToRefCard (item) {
      for (let key in this.$refs.addEdit.regInfo) {
        if (key !== 'specialitiesCount') {
          this.$refs.addEdit.regInfo[key] = item[key]
        }
      }
      this.$refs.addEdit.mode = 'edit'
      const specInfo = await this.getSpecialitiesCount()

      this.$refs.addEdit.regInfo.specialitiesCount = []
      specInfo.forEach((el) => {
        const info = { ...el }
        this.$refs.addEdit.regInfo.specialitiesCount.push(info)
      })
      if (specInfo[0]?.year) {
        this.$refs.addEdit.regInfo.year = specInfo[0].year
      }
    },
    async getSpecialitiesCount () {
      const organizationId = this.$refs.addEdit.regInfo.organizationId
      const url = `${process.env.VUE_APP_SERVER_URL}/extra/accred_info_extended_spec?organization_id=${organizationId}`
      let specInfo = await fetch(url)
      specInfo = await specInfo.json()
      return specInfo
    },
    close () {
      this.dialog = false
    }
  }

}
</script>

<style scoped>
.v-data-table .v-toolbar__content > .v-btn {
  margin-right: 10px;
}
</style>