<template>
  <v-card flat>
    <v-card-title>Анкетирование</v-card-title>
    <v-card-text>
      <div style="width: 30%">
        <div v-if="myActiveWorksheets.length > 0">
          <v-select
              v-model="worksheetType"
              :item-text="item => `Мероприятие ${item.number}. ${item.date_name}`"
              return-object
              :items="myActiveWorksheets" />
          <v-btn color="primary" @click="startWorksheet" :disabled="!worksheetType">
            Начать
          </v-btn>
        </div>
        <h3
            v-else-if="loaded && myActiveWorksheets.length === 0"
            >У вас нет доступных анкетирований</h3>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "WorksheetChooser",
  data () {
    return {
      loaded: false,
      worksheetType: '',
      worksheetTypes: [],
      myActiveWorksheets: []
    }
  },
  computed: {
    ...mapState({ userId: (state) => state.user.userId }),
  },
  async beforeMount () {
    const myWorksheetsUrl = `${process.env.VUE_APP_SERVER_URL}/catalog/my_active_worksheets?userId=${this.userId}`
    const response = await fetch(myWorksheetsUrl)
    if (response.ok) { // если HTTP-статус в диапазоне 200-299
      // получаем тело ответа (см. про этот метод ниже)
      this.myActiveWorksheets = await response.json();
    } else {
      alert("Ошибка HTTP: " + response.status);
    }
    this.loaded = true
  },
  methods: {
    startWorksheet () {
      window.open(
          `${this.worksheetType.type}?id=${this.worksheetType.id}`,
          '_blank'
      );
    }
  }
}
</script>