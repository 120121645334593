<template>
  <v-form ref="accredOrganizationWorksheet">
    <v-card
        max-width="640"
        class="mx-auto"
        flat
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-app-bar
                  dark
                  color="#4285F4"
                  height="10px"
              >
              </v-app-bar>
              <v-card-title class="text-h5">
                Анкета для аккредитационных площадок
              </v-card-title>
              <v-card-text>
                <v-card-subtitle class="errorColor">* - Обязательный вопрос</v-card-subtitle>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <div style="display: flex; align-items: flex-start">
                <span class="subtitle subtitle--width95">
                  Вид аккредитации:
                </span>
                <span class="subtitle subtitle--padded errorColor">*</span>
              </div>
              <v-card-text>
                <v-radio-group v-model="info.accredType" :rules="[v => !!v]">
                  <v-radio
                      label="Первичная"
                      value="first"
                  ></v-radio>
                  <v-radio
                      label="Первичная специализированная"
                      value="firstSpec"
                  ></v-radio>
                </v-radio-group>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <div style="display: flex; align-items: flex-start">
                <span class="subtitle subtitle--width95">
                  Специальность, по которой проводится аккредитация
                </span>
                <span class="subtitle subtitle--padded errorColor">*</span>
              </div>
              <v-card-text>
                <v-autocomplete
                    v-model="info.specialityId"
                    :items="specialities"
                    item-value="code"
                    item-text="name"
                    :rules="[v => !!v]"
                    no-data-text="Специальностей не найдено"
                ></v-autocomplete>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <div style="display: flex; align-items: flex-start">
                <span class="subtitle subtitle--width95">
                  Достаточно ли в Паспорте станции информации для организации станции ОСКЭ:
                </span>
                <span class="subtitle subtitle--padded errorColor">*</span>
              </div>
              <v-card-text>
                <v-radio-group v-model="info.hasEnoughInformation" :rules="[v => !!v]">
                  <v-radio
                      label="Да"
                      :value="true"
                  />
                  <v-radio
                      label="Нет"
                      :value="false"
                  />
                </v-radio-group>
              </v-card-text>
            </v-card>
          </v-col>


          <v-col cols="12">
            <v-card>
              <v-card-subtitle>
                <span style="color:black !important;">
                  Если в предыдущем вопросе Вы выбрали ответ «нет», напишите, пожалуйста, какой информации Вам не
                  хватает:
                </span>
              </v-card-subtitle>
              <v-card-text>
                <v-text-field label="Мой ответ" v-model="info.informationWish" />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card>
              <div style="display: flex; align-items: flex-start">
                <span class="subtitle subtitle--width95">
                  В Паспорте станции ОСКЭ есть таблица со списком расходных материалов из расчёта на 1 попытку
                  аккредитуемого. Были ли случаи несоответствия количества расходных материалов, указанного в таблице, и
                  фактического?
                </span>
                <span class="subtitle subtitle--padded errorColor">*</span>
              </div>
              <v-card-text>
                <v-radio-group v-model="info.materialLack" :rules="[v => !!v]">
                  <v-radio
                      label="Да"
                      :value="true"
                  />
                  <v-radio
                      label="Нет"
                      :value="false"
                  />
                </v-radio-group>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <v-card-subtitle>
                <span style="color: black !important;">
                  В случае выбора ответа «ДА» в прошлом вопросе укажите,
                  пожалуйста, название конкретного паспорта станции ОСКЭ и то, чего не хватало:
                </span>
              </v-card-subtitle>
              <v-card-text>
                <v-text-field label="Мой ответ" v-model="info.materialLackText" />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card>
              <div style="display: flex; align-items: flex-start">
                <span class="subtitle subtitle--width95">
                  Используемое на станции ОСКЭ симуляционное оборудование
                  доступно обучающимся вне проведения аккредитации:
                </span>
                <span class="subtitle subtitle--padded errorColor">*</span>
              </div>
              <v-card-text>
                <v-radio-group v-model="info.equipmentAccess" :rules="[v => !!v]">
                  <v-radio
                      label="А. Да, доступно для неограниченного количества тренировок"
                      value="noLimit"
                  />
                  <v-radio
                      label="Б. Да, у всех обучающихся есть возможность выполнить по одной попытке"
                      value="limited"
                  />
                  <v-radio
                      label="В. Да, но с симуляционным оборудованием работает только преподаватель, обучающиеся наблюдают"
                      value="onlyTeacher"
                  />
                </v-radio-group>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <v-card-subtitle>
                <span style="color: black !important;">
                  Если на прошлом вопросе Вы дали любой ответ, кроме ответа А,
                  поясните, пожалуйста, причину?
                </span>
              </v-card-subtitle>
              <v-card-text>
                <v-checkbox
                    v-for="(value, key) in equipmentAccessOptions"
                    :key="`equipmentAccess-${key}`"
                    v-model="info.equipmentAccessFeature"
                    :label="value"
                    :value="key"
                    dense
                />
                <v-text-field
                    v-if="info.equipmentAccessFeature.includes('other')"
                    v-model="info.equipmentAccessFeatureText"
                    placeholder="Другое" />
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <div style="display: flex; align-items: flex-start">
                <span class="subtitle subtitle--width95">
                  Напишите, как Вы думаете, что можно сделать, чтобы повысить качество оценки
                  практических навыков по конкретной специальности в процессе аккредитации?
                </span>
                <span class="subtitle subtitle--padded errorColor">*</span>
              </div>
              <v-card-text>
                <v-text-field
                    v-model="info.suggestion"
                    :rules="[v => !!v]"
                    label="Мой ответ" />
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <div style="display: flex; align-items: flex-start">
                <span class="subtitle subtitle--width95">
                  Что в процессе реализации станций ОСКЭ вызывает у Вас трудности?
                </span>
                <span class="subtitle subtitle--padded errorColor">*</span>
              </div>
              <v-card-text>
                <v-text-field
                    v-model="info.difficulties"
                    :rules="[v => !!v]"
                    label="Мой ответ" />
              </v-card-text>
            </v-card>
          </v-col>

        </v-row>
      </v-container>

      <v-btn color="primary" class="worksheetSubmitBtn" @click="submitForm">Отправить</v-btn>
    </v-card>
  </v-form>
</template>

<script>
import { dbMixin, formsMixin } from "@/mixins"
import { mapGetters } from "vuex"

export default {
  name: 'AccredWorksheet',
  mixins: [dbMixin, formsMixin],
  data () {
    return {
      specialities: [],
      equipmentAccessOptions: {
        expensive: 'Дорогие расходные материалы к симуляционному оборудованию (например, кожа, сменные ребра для пункции и т.д.),' +
            ' их не хватает на всех обучающихся',
        broken: 'Симуляторы часто выходят из строя, недоступны во время ремонта',
        demonstrationOnly: 'Преподаватели не доверяют симуляторы обучающимся / считают,' +
            ' что демонстрации достаточно для обучения',
        teacherIncompetence: 'Преподаватели не умеют пользоваться симуляционным оборудованием',
        rentEquipment: 'Симуляционного оборудования нет в наличии (на период аккредитации симуляторы берутся напрокат' +
            ' или используется реальное медицинское оборудование, временно предоставляемое' +
            ' из отделений стационаров и поликлиник)',
        other: 'Другое'
      },
      info: {
        type: 'accredOrganization',
        accredType: '',
        specialityId: '',
        hasEnoughInformation: '',
        informationWish: '',
        materialLack: '',
        materialLackText: '',
        equipmentAccess: '',
        equipmentAccessFeature: [],
        equipmentAccessFeatureText: '',
        suggestion: '',
        difficulties: ''
      }
    }
  },
  beforeMount () {
    this.getCatalogData('specialitiesWithLevel')
  },
  computed: {
    ...mapGetters(['USER']),
  },
  methods: {
    async submitForm () {
      if (!this.$refs.accredOrganizationWorksheet.validate()) {
        return
      }
      const url = `${process.env.VUE_APP_SERVER_URL}/worksheet/register`
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...this.info, personId: this.USER.userId, worksheetId: this.$route.query.id }),
      });
      if (response.status === 200) {
        await this.$dialog.notify.success('Анкета сохранена', { timeout: 1500 })
      } else {
        await this.errorHandler(response, 'Ошибка при сохранении анкеты')
      }

    }
  }
}


</script>

<style scoped>
.questionTitle {
  line-height: 24px;
  color: #202124;
  font-weight: 400;
  font-size: 12pt;
  font-family: 'docs-Roboto', Helvetica, Arial, sans-serif;
}
</style>

