<template>
  <v-form ref="anReanimWorksheet">
    <v-card
        max-width="640"
        class="mx-auto"
        flat
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-app-bar
                  dark
                  color="#4285F4"
                  height="10px"
              >
              </v-app-bar>
              <v-card-title class="text-h5">
                Анестизология реаниматология
              </v-card-title>
              <v-card-subtitle class="errorColor">* - Обязательный вопрос</v-card-subtitle>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card>
              <div style="display: flex; align-items: flex-start">
                <span class="subtitle subtitle--width95">
                  Укажите свою аккредитационную площадку
                </span>
                <span class="subtitle subtitle--padded errorColor">*</span>
              </div>
              <v-card-text>
                <v-autocomplete
                    v-model="info.organizationId"
                    :items="organizations"
                    item-value="code"
                    item-text="name"
                    :rules="[v => !!v]"
                    label="Укажите свою аккредитационную площадку"
                    no-data-text="Организаций не найдено"
                ></v-autocomplete>

              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <div style="display: flex; align-items: flex-start">
                <span class="subtitle subtitle--width95">
                  Есть ли на вашей аккредитационной площадки в наличии фантом для пункции и катетеризации
                  эпидурального пространства, позволяющий достоверно имитировать выполнение эпидуральной пункции
                  методикой «потери сопротивления» и введение эпидурального катетера?
                </span>
                <span class="subtitle subtitle--padded errorColor">*</span>
              </div>
              <v-card-text>
                <v-radio-group v-model="info.hasPhantomPunc" :rules="[v => v !== '']">
                  <v-radio
                      label="Да"
                      :value="true"
                  ></v-radio>
                  <v-radio
                      label="Нет"
                      :value="false"
                  ></v-radio>
                </v-radio-group>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <div style="display: flex; align-items: flex-start">
                <span class="subtitle subtitle--width95">
                  Есть ли на вашей аккредитационной площадки в наличии фантом-симулятор для отработки
                  люмбальной пункции, позволяющий достоверно имитировать выполнение люмбальной пункции с получением
                  спинномозговой жидкости?
                </span>
                <span class="subtitle subtitle--padded errorColor">*</span>
              </div>
              <v-card-text>
                <v-radio-group v-model="info.hasPhantomSim" :rules="[v => v !== '']">
                  <v-radio
                      label="Да"
                      :value="true"
                  ></v-radio>
                  <v-radio
                      label="Нет"
                      :value="false"
                  ></v-radio>
                </v-radio-group>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <div style="display: flex; align-items: flex-start">
                <span class="subtitle subtitle--width95">
                  Есть ли на вашей аккредитационной площадки в наличии манекен для катетеризации
                  центральных вен, позволяющую выполнять пункцию внутренней яремной вены под контролем ультразвука?
                </span>
                <span class="subtitle subtitle--padded errorColor">*</span>
              </div>
              <v-card-text>
                <v-radio-group v-model="info.hasMannequin" :rules="[v => v !== '']">
                  <v-radio
                      label="Да"
                      :value="true"
                  ></v-radio>
                  <v-radio
                      label="Нет"
                      :value="false"
                  ></v-radio>
                </v-radio-group>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <div style="display: flex; align-items: flex-start">
                <span class="subtitle subtitle--width95">
                  Есть ли на вашей аккредитационной площадки в наличии портативный (переносной) аппарат
                  ультразвукового исследования с линейный датчиком?
                </span>
                <span class="subtitle subtitle--padded errorColor">*</span>
              </div>
              <v-card-text>
                <v-radio-group v-model="info.hasUltraSound" :rules="[v => v !== '']">
                  <v-radio
                      label="Да"
                      :value="true"
                  ></v-radio>
                  <v-radio
                      label="Нет"
                      :value="false"
                  ></v-radio>
                </v-radio-group>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-btn color="primary" class="worksheetSubmitBtn" @click="submitForm">Отправить</v-btn>
    </v-card>
  </v-form>
</template>

<script>
import { dbMixin, formsMixin } from "@/mixins"
import { mapGetters } from "vuex"

export default {
  name: "AnReanimWorksheet",
  mixins: [dbMixin, formsMixin],
  data () {
    return {
      info: {
        type: 'anReanim',
        organizationId: '',
        hasPhantomPunc: '',
        hasPhantomSim: '',
        hasMannequin: '',
        hasUltraSound: ''
      },
      organizations: []
    }
  },
  beforeMount () {
    this.getCatalogData('organizations')
  },
  computed: {
    ...mapGetters(['USER']),
  },
  methods: {
    async submitForm () {
      if (!this.$refs.anReanimWorksheet.validate()) {
        return
      }
      const url = `${process.env.VUE_APP_SERVER_URL}/worksheet/register`
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...this.$data.info, personId: this.USER.userId, worksheetId: this.$route.query.id }),
      });
      if (response.status === 200) {
        await this.$dialog.notify.success('Анкета сохранена', { timeout: 1500 })
      } else {
        await this.errorHandler(response, 'Ошибка при сохранении анкеты')
      }

    },
  }
}
</script>

<style scoped>

</style>