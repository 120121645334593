<template>
  <v-container class="primary" fill-height fluid>
    <!--Не показывает лого на экранах меньше md-->
    <div class="d-none d-md-block">
      <img alt="Сеченовский университет" src="@/assets/logo_blue.png">
    </div>
    <v-layout align-center justify-center>
      <v-flex lg5 md6 sm8 xs12 xl4>
        <v-flex class="mb-5 white--text" text-center>
          <div class="text-h4 font-weight-medium">
            Мероприятия Методического центра аккредитации
          </div>
          <hr>
        </v-flex>

        <v-card class="elevation-8 pr-4 pt-4 pb-4 pl-4">
          <v-form @submit.prevent="submit">
            <v-card-text class="py-3">
              <v-select
                  v-model="regType"
                  :items="regTypes"
                  item-text="name"
                  item-value="id"
                  label="Выберите тип регистрации"
                  dense
              ></v-select>
            </v-card-text>
            <v-card-actions>
              <v-layout class="wrap">
                <v-btn :disabled="!regType" block color="primary" type="submit">
                  Начать регистрацию
                </v-btn>
              </v-layout>
            </v-card-actions>
          </v-form>
        </v-card>
        <v-flex class="mb-5 white--text" text-center>
          <v-btn
              text
              color="white"
              @click="downloadInstruction">
            <v-icon>
              mdi-help-circle-outline
            </v-icon>
             Инструкция пользователя
          </v-btn>
        </v-flex>
      </v-flex>
    </v-layout>
    <DialogChooser :dialog-name="regType" :show=showDialog @close="closeDialog" />
  </v-container>
</template>
<script>

import { saveAs } from 'file-saver'
import DialogChooser from "@/components/DialogChooser"
import { dbMixin } from "@/mixins"

export default {
  name: 'StartPage',
  components: { DialogChooser },
  mixins: [dbMixin],

  data () {
    return {
      showDialog: false,
      regTypes: [],
      regType: ''
    }
  },
  async beforeMount () {
    await this.getCatalogData('regTypes', { is_active: true })
  },
  head () {
    return {
      title: 'Вход'
    }
  },
  methods: {
    submit () {
      this.showDialog = true
    },
    downloadInstruction () {
      const url = `${process.env.VUE_APP_SERVER_URL}/files/download_instruction`
      saveAs(url, 'Инструкция.pdf')
    },
    closeDialog () {
      this.showDialog = false
    }
  }

}
</script>

<style scoped>
body {
  overflow: hidden;
}

img {
  position: absolute;
  left: 0;
  top: 20px;
  height: 200px;
}

</style>
