<template>
  <v-card flat>
    <slot name="cardTitle"></slot>
    <v-card-text>
      <v-form ref="form">
        <v-autocomplete
            v-if="showCardActions"
            v-model="regInfo.worksheetId"
            :items="worksheets"
            item-value="id"
            :item-text="item => `Мероприятие ${item.id}. ${item.name} [${date(item.date)}]`"
            :rules="[v => !!v]"
            label="Анкетирование"
            placeholder="Выберите анкетирование"
            no-data-text="Актуальные мероприятия анкетирования не найдены"
        >
        </v-autocomplete>
        <v-autocomplete
            v-if="!showCardActions"
            v-model="regInfo.worksheets"
            :items="worksheets"
            item-value="id"
            :item-text="item => `Мероприятие ${item.id}. ${item.name} [${item.date}]`"
            multiple
            label="Анкетирование"
            placeholder="Выберите анкетирование"
            :rules="[v => v.length > 0]"
            return-object
            no-data-text="Актуальные мероприятия анкетирования не найдены"
        >
          <template #selection="{ item }">
            <v-chip
                outlined
                color="primary"
                close
                close-icon="mdi-close"
                @click:close="removeWorksheet(item.id)"
            >
              <span>{{ item.name }}</span>
            </v-chip>
          </template>
        </v-autocomplete>
        <v-autocomplete
            v-model="regInfo.regionSubjectId"
            :items="regions"
            item-value="code"
            item-text="name"
            :rules="[v => !!v]"
            label="Субъект (Регион)"
            no-data-text="Нет данных"
        ></v-autocomplete>
        <v-autocomplete
            v-model="regInfo.organizationId"
            :items="organizations"
            item-value="code"
            item-text="name"
            :rules="[v => !!v]"
            label="Организация"
            no-data-text="Организаций не найдено"
        ></v-autocomplete>
        <person-card v-if="!loadedInfo" ref="personCard"></person-card>
        <v-text-field v-model="regInfo.position" label="Занимаемая должность" :rules="[v => !!v]" />
      </v-form>
    </v-card-text>
    <v-card-actions v-if="showCardActions">
      <v-spacer />
      <v-btn
          outlined
          color="primary"
          @click="close"
      >
        Отмена
      </v-btn>
      <v-btn
          outlined
          color="primary"
          @click="save"
      >
        Сохранить
      </v-btn>
    </v-card-actions>
  </v-card>

</template>

<script>
import PersonCard from "@/components/forms/PersonCard"
import { dateMixin, dbMixin, formsMixin, registerMixin } from "@/mixins"

export default {
  name: "WorksheetParticipant",
  components: { PersonCard },
  mixins: [dbMixin, dateMixin, registerMixin, formsMixin],
  data () {
    return {
      role: 'worksheetParticipant',
      organizations: [],
      regions: [],
      worksheets: [],
      regInfo: {
        organizationId: '',
        regionSubjectId: '',
        worksheetId: '',
        worksheets: [],
        surname: '',
        firstname: '',
        patronymic: '',
        phone: '',
        email: '',
        degree: '',
        position: ''
      }
    }
  },
  async beforeMount () {
    await this.getCatalogData('regions')
    await this.getCatalogData('organizations')
    await this.getCatalogData('worksheets', { isActual: true })
  },

  methods: {
    close () {
      this.$emit('close')
    },
    removeWorksheet (worksheetId) {
      this.regInfo.worksheets = this.regInfo.worksheets.filter(el => el.id != worksheetId)
    }
  }
}
</script>

<style scoped>

</style>