<template>
  <v-form ref="expertWorksheet">
    <v-card
        max-width="640"
        class="mx-auto"
        flat
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-app-bar
                  dark
                  color="#4285F4"
                  height="10px"
              >
              </v-app-bar>
              <v-card-title class="text-h5">
                Анкета эксперта
              </v-card-title>
              <v-card-text>
                <v-card-subtitle class="errorColor">* - Обязательный вопрос</v-card-subtitle>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card>
              <div style="display: flex; align-items: flex-start">
                <span class="subtitle">
                  Оцените удобство использования тренажера виртуальной реальности для аккредитуемого по
                  5-балльной шкале:
                </span>
                <span class="subtitle subtitle--padded errorColor">*</span>
              </div>
              <v-card-text>
                <v-radio-group
                    v-model="comfort"
                    :rules="[v => !!v]">
                  <v-radio
                      v-for="n in 5"
                      :key="`Grade-${n}`"
                      :label="`${n}`"
                      :value="n"
                  ></v-radio>
                </v-radio-group>

              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <div style="display: flex; align-items: flex-start">
                <span class="subtitle">
                  На Ваш взгляд необходима ли предварительная подготовка (работа с тестовыми сценариями
                  тренажера виртуальной реальности) для аккредитуемого?
                </span>
                <span class="subtitle subtitle--padded errorColor">*</span>
              </div>
              <v-card-text>
                <v-radio-group
                    v-model="prep"
                    :rules="[v => !!v]">
                  <v-radio
                      label="Да"
                      value="yes"
                  ></v-radio>
                  <v-radio
                      label="Нет"
                      value="no"
                  ></v-radio>
                  <v-radio
                      label="Другое"
                      value="other"
                  ></v-radio>
                  <v-text-field v-if="prep === 'other'" v-model="prepText" placeholder="Другое"></v-text-field>
                </v-radio-group>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <div style="display: flex; align-items: flex-start">
                <span class="subtitle">
                  Оцените корректность оценки знаний аккредитуемого с использованием тренажера виртуальной
                  реальности по 5-балльной шкале:
                </span>
                <span class="subtitle subtitle--padded errorColor">*</span>
              </div>
              <v-card-text>
                <v-radio-group
                    v-model="accred"
                    :rules="[v => !!v]">
                  <v-radio
                      v-for="n in 5"
                      :key="`accred-${n}`"
                      :label="`${n}`"
                      :value="n"
                  ></v-radio>
                </v-radio-group>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <div style="display: flex; align-items: flex-start">
                <span class="subtitle">
                  Оцените эффективность тренажера виртуальной реальности для формирования профессиональных
                  компетенций врача-дерматовенеролога по 5-балльной шкале:
                </span>
                <span class="subtitle subtitle--padded errorColor">*</span>
              </div>
              <v-card-text>
                <v-radio-group
                    v-model="venera"
                    :rules="[v => !!v]">
                  <v-radio
                      v-for="n in 5"
                      :key="`venera-${n}`"
                      :label="`${n}`"
                      :value="n"
                  ></v-radio>
                </v-radio-group>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <div style="display: flex; align-items: flex-start">
                <span class="subtitle">
                  Оцените условную реалистичность симулированного приема врача с использованием тренажера
                  виртуальной реальности по 5-балльной шкале:
                </span>
                <span class="subtitle subtitle--padded errorColor">*</span>
              </div>
              <v-card-text>
                <v-radio-group
                    v-model="docMethod"
                    :rules="[v => !!v]">
                  <v-radio
                      v-for="n in 5"
                      :key="`docMethod-${n}`"
                      :label="`${n}`"
                      :value="n"
                  ></v-radio>
                </v-radio-group>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <div style="display: flex; align-items: flex-start">
                <span class="subtitle">
                  Есть ли на Ваш взгляд необходимость в постоянном присутствии сотрудника технической
                  службы рядом с аккредитуемым во время выполнения сценария на тренажере виртуальной реальности?
                </span>
                <span class="subtitle subtitle--padded errorColor">*</span>
              </div>
              <v-card-text>
                <v-radio-group
                    v-model="support"
                    :rules="[v => !!v]">
                  <v-radio
                      label="Да"
                      value="yes"
                  ></v-radio>
                  <v-radio
                      label="Нет"
                      value="no"
                  ></v-radio>
                  <v-radio
                      label="Другое"
                      value="other"
                  ></v-radio>
                  <v-text-field v-if="support === 'other'" v-model="supportText" placeholder="Другое"></v-text-field>
                </v-radio-group>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <div style="display: flex; align-items: flex-start">
                <span class="subtitle">
                  Есть ли необходимость в контроле членом АПК действий аккредитуемого через монитор при
                  условии, что оценка производится тренажером виртуальной реальности автоматически?
                </span>
                <span class="subtitle subtitle--padded errorColor">*</span>
              </div>
              <v-card-text>
                <v-radio-group
                    v-model="control"
                    :rules="[v => !!v]">
                  <v-radio
                      label="Да, член АПК должен отслеживать каждое действие аккредитуемого"
                      value="yesControl"
                  ></v-radio>
                  <v-radio
                      label="Нет, член АПК может контролировать только соблюдение процедуры аккредитации"
                      value="noControl"
                  ></v-radio>
                </v-radio-group>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <div style="display: flex; align-items: flex-start">
                <span class="subtitle">
                  Оцените Ваши субъективные ощущения (Ваш комфорт) при контроле по монитору действий
                  аккредитуемого в рамках сценария на тренажере виртуальной реальности
                </span>
                <span class="subtitle subtitle--padded errorColor">*</span>
              </div>
              <v-card-text>
                <v-radio-group
                    v-model="ctrlFeeling"
                    :rules="[v => !!v]">
                  <v-radio
                      label="Никаких неприятных ощущений не испытывал(а)"
                      value="good"
                  ></v-radio>
                  <v-radio
                      label="Развилось головокружение"
                      value="dizzy"
                  ></v-radio>
                  <v-radio
                      label="Развилась тошнота"
                      value="nausea"
                  ></v-radio>
                  <v-radio
                      label="Другое"
                      value="other"
                  ></v-radio>
                </v-radio-group>
                <v-text-field v-if="ctrlFeeling === 'other'" v-model="ctrlFeelingText"
                              placeholder="Другое"></v-text-field>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <div style="display: flex; align-items: flex-start">
                <span class="subtitle">
                  Оценка действий аккредитуемого с использованием тренажера виртуальной реальности
                  на Ваш взгляд более соответствует:
                </span>
                <span class="subtitle subtitle--padded errorColor">*</span>
              </div>
              <v-card-text>
                <v-radio-group
                    v-model="similiar"
                    :rules="[v => !!v]">
                  <v-radio
                      label="Оценке практических навыков на станциях ОСКЭ (практический этап ПСА)"
                      value="similiarPca"
                  ></v-radio>
                  <v-radio
                      label="Решению ситуационных задач (как вариант развернутой клинической задачи)"
                      value="similiarCase"
                  ></v-radio>
                </v-radio-group>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <v-card-subtitle>Ваши замечания / предложения по улучшению сценариев тренажера виртуальной реальности:
              </v-card-subtitle>
              <v-card-text>
                <v-text-field v-model="scenarioSuggestion" label="Мой ответ"></v-text-field>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <v-card-subtitle>Ваши замечания/предложения по улучшению процедуры использования тренажера виртуальной
                реальности:
              </v-card-subtitle>
              <v-card-text>
                <v-text-field v-model="procedureSuggestion" label="Мой ответ"></v-text-field>
              </v-card-text>
            </v-card>
          </v-col>

        </v-row>
      </v-container>

      <v-btn color="primary" class="worksheetSubmitBtn" @click="submitForm">Отправить</v-btn>
    </v-card>
  </v-form>
</template>

<script>
import { formsMixin } from "@/mixins"
import { mapGetters } from "vuex"

export default {
  name: 'ExpertWorksheet',
  mixins: [formsMixin],
  data () {
    return {
      type: 'expert',
      comfort: 0,
      prep: '',
      prepText: '',
      accred: 0,
      venera: 0,
      docMethod: 0,
      support: '',
      supportText: '',
      control: '',
      ctrlFeeling: '',
      ctrlFeelingText: '',
      similiar: 0,
      scenarioSuggestion: '',
      procedureSuggestion: ''
    }
  },
  computed: {
    ...mapGetters(['USER']),
  },
  methods: {
    async submitForm () {
      if (!this.$refs.expertWorksheet.validate()) {
        return
      }
      const url = `${process.env.VUE_APP_SERVER_URL}/worksheet/register`
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...this.$data, personId: this.USER.userId, worksheetId: this.$route.query.id }),
      });
      if (response.status === 200) {
        await this.$dialog.notify.success('Анкета сохранена', { timeout: 1500 })
      } else {
        await this.errorHandler(response, 'Ошибка при сохранении анкеты')
      }

    }
  }
}


</script>

