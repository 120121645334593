<template>
  <v-dialog
      :value="show"
      persistent
  >
    <component v-if="dialogName && show" :is="dialogName" :show-card-actions="true" @close="close">
      <template #cardTitle>
        <v-card-title>{{ titleDialogDict[dialogName] }}</v-card-title>
      </template>
    </component>
  </v-dialog>
</template>

<script>

import * as dialogsComponents from "./forms";

export default {
  name: "DialogChooser",
  components: { ...dialogsComponents },
  props: {
    dialogName: {
      type: String
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      titleDialogDict: {
        Accred: "Регистрация на обучение членов АК",
        BankExpert: "Регистрация пользователя в качестве эксперта банка ТЗ",
        PackageExpert: "Регистрация пользователя в качестве эксперта пакета ТЗ",
        CaseExpert: "Регистрация пользователя в качестве эксперта кейсов",
        Stdpatient: "Регистрация пользователя в качестве стандартизированного пациента",
        WebinarParticipant: "Регистрация пользователя на вебинар"
      }

    }

  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>